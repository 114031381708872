<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shippingScheduleEdit" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelected.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        readonly
                        v-model="suppliersSelected"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        persistent-hint
                        margin-bottom="0"
                        :hint="setSrhSupplier()"
                        pa-3
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelected }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    readonly
                    v-model="suppliersSelected"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    :hint="setSrhSupplier()"
                    pa-3
                  ></v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhShipping()"
                class="txt-singles"
                persistent-hint
                :rules="[rules.inputRequired]"
                @change="changeShipping"
                :search-input.sync="getShippingSelected"
                :readonly="isDisableShippingDropdown"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷No -->
            <div v-if="delConfirm == false" class="textbox-arrivalNo">
              <v-text-field
                dense
                outlined
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_shippingNo')"
                :rules="[rules.inputRequired]"
                class="txt-singles"
              />
            </div>
            <div v-else class="textbox-arrivalNo">
              <v-text-field
                dense
                outlined
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_shippingNo')"
                :rules="[rules.inputRequired]"
                class="txt-singles"
                :readonly="true"
              />
            </div>

            <span class="require asterisk-spacer" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日付-->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                :readonly="delConfirm"
                dense
                outlined
                class="txt-singles"
                v-model="shippingScheduleDate"
                :label="$t('label.lbl_shippingScheduleDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                :clearable="!delConfirm"
              />
            </div>
            <span class="require asterisk-spacer">*</span>
            <div v-if="delConfirm == false">
              <div class="calender-btn-area" style="float: left">
                <!-- 出荷予定日カレンダー-->
                <v-menu
                  v-model="shippingScheduleDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon class="icon-calendar">mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="shippingScheduleDateCal"
                    @input="shippingScheduleDateMenu = false"
                    max="2099-12-31"
                  ></v-date-picker>
                  <!-- :allowed-dates="allowedDates" -->
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                  <!-- 前日-->
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                  <!-- 次日-->
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品質区分-->
            <div v-if="delConfirm == false" class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingStatusSelected"
                :items="shippingStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <div v-else class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingStatusSelected"
                :items="shippingStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                persistent-hint
                :rules="[rules.inputRequired]"
                :readonly="true"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle
              mandatory
              v-model="isRes"
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame || delConfirm"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <!-- 2段目 -->
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!-- トラック-->
              <div v-if="delConfirm == false" class="serch-textbox-tr" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="truckNm"
                  maxlength="50"
                  :label="$t('label.lbl_shippingCompanyName')"
                  class="txt-singles"
                  readonly
                  clearable
                  clear-icon="mdi-close-circle"
                  :rules="[rules.inputRequired]"
                />
              </div>
              <div v-else class="serch-textbox-tr" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="truckNm"
                  maxlength="50"
                  :label="$t('label.lbl_shippingCompanyName')"
                  class="txt-singles"
                  clear-icon="mdi-close-circle"
                  :rules="[rules.inputRequired]"
                  :readonly="true"
                  :clearable="!delConfirm"
                />
              </div>

              <span class="require asterisk-spacer" style="float: left">*</span>

              <!-- 輸送会社選択ボタン -->
              <div style="float: left">
                <v-btn
                  color="primary"
                  class="api-btn"
                  :disabled="delConfirm"
                  v-on:click="openTruck"
                >
                  {{ $t("btn.btn_addShpAdress") }}
                </v-btn>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 有償/無償-->
              <div style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="isPaid"
                  color="blue darken-2"
                  id="btn-toggle-btn-padding"
                >
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                    :disabled="delConfirm"
                  >
                    {{ item.text }}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 無償理由選択プルダウン-->
              <div v-if="delConfirm == false" class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <div v-else class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                  :readonly="true"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 処理区分-->
              <div class="search-autocomplete" style="float: left">
                <v-text-field
                  dense
                  readonly
                  v-model="processDivSelected"
                  :label="$t('label.lbl_processingdivision')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 納品日 -->
              <div class="date-style" style="float: left">
                <v-text-field
                  :readonly="delConfirm"
                  outlined
                  dense
                  v-model="dateToDelivery"
                  :label="$t('label.lbl_delDate')"
                  @change="changeDeliveryDate"
                  class="txt-singles"
                  clear-icon="mdi-close-circle"
                  :error-messages="alertMessage"
                  :rules="
                    this.isDeliveryDateRequireFlg
                      ? [
                          rules.yyyymmdd,
                          rules.isFutureDate(
                            dateToDelivery,
                            shippingScheduleDate,
                            $t('label.lbl_shippingScheduleDate')
                          ),
                        ]
                      : [
                          rules.yyyymmdd_create,
                          rules.isFutureDate(
                            dateToDelivery,
                            shippingScheduleDate,
                            $t('label.lbl_shippingScheduleDate')
                          ),
                        ]
                  "
                  :clearable="!delConfirm"
                />
              </div>
              <span
                v-if="this.isDeliveryDateRequireFlg"
                class="require asterisk-spacer"
                style="float: left"
                >*</span
              >
              <div v-if="delConfirm == false">
                <div class="calender-btn-area" style="float: left">
                  <v-menu
                    v-model="dateToDeliveryMenu"
                    :close-on-content-click="false"
                    :nudge-right="-100"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon class="icon-calendar">mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="dateToDeliveryCal"
                      @input="dateToDeliveryMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-btn
                    id="btn-yesterday"
                    class="day-ctrl-btn"
                    @click="prevDeliveryDate"
                    text
                    x-small
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    id="btn-tomorow"
                    class="day-ctrl-btn"
                    @click="nextDeliveryDate"
                    text
                    x-small
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-row>
          <v-row>
            <!-- 3段目(表示項目) -->
            <div class="search-row-exeBtn first-search-item" :style="deliverystyle">
              <!-- 届け先名-->
              <div class="search-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="destinationDisplay"
                  maxlength="200"
                  :label="$t('label.lbl_deliveryname')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  disabled="disabled"
                />
              </div>
              <!-- 届け先名-->
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 届け先住所-->
              <div class="search-textbox-remark" style="float: left">
                <v-text-field
                  outlined
                  dense
                  v-model="destinationAddressDisplay"
                  maxlength="200"
                  :label="$t('label.lbl_popAddress')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  disabled="disabled"
                />
              </div>
              <!-- 届け先住所-->
            </div>
          </v-row>
          <v-row>
            <!-- 4段目 -->
            <div v-if="delConfirm == false" class="search-row-exeBtn first-search-item">
              <!-- 備考欄-->
              <v-textarea
                outlined
                id="remarks"
                dense
                v-model="remarks"
                :label="$t('label.lbl_remarksTextbox')"
                rows="1"
              />
            </div>
            <div v-else class="search-row-exeBtn first-search-item">
              <!-- 備考欄-->
              <v-text-field
                id="remarks"
                dense
                v-model="remarks"
                :label="$t('label.lbl_remarksTextbox')"
                class="txt-single"
                :readonly="true"
              />
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <div class="btn-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn class="other-btn" v-on:click="backbtn">
                  {{ $t("btn.btn_back") }}
                </v-btn>
                <!-- 追加ボタン-->
                <v-btn
                  id="other-btn"
                  class="other-btn"
                  :disabled="isDoAddButton"
                  value="1"
                  @click="add"
                >
                  {{ $t("btn.btn_add") }}
                </v-btn>
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addShpSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <div class="btn-area">
                <!-- 個人宅ボタン-->
                <v-btn
                  color="primary"
                  class="api-btn"
                  v-on:click="btnIndividual"
                  :disabled="individualButton"
                >
                  {{ $t("btn.btn_individual") }}
                </v-btn>
                <!-- 個人宅ボタン-->
                <!-- 修正登録ボタン-->
                <v-btn class="post-btn" :disabled="ableConfirm" value="1" v-on:click="shpScheAdd">
                  {{ $t("btn.btn_fixinsert") }}
                </v-btn>

                <!-- 伝票削除ボタン-->
                <v-btn class="post-btn" :disabled="delConfirm" value="2" v-on:click="shpScheDel">
                  {{ $t("btn.btn_slipdelete") }}
                </v-btn>

                <!-- メッセージダイアログ-->
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addShpSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
          <!-- 戻るボタンのダイアログ -->
          <v-dialog v-model="updateDialogMessage" :max-width="800">
            <v-card>
              <v-card-title class="blue-grey lighten-3" primary-title>
                {{ $t("btn.btn_ok") }}
              </v-card-title>
              <v-card-text class="pa-4">
                <p>{{ backMessage }}</p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="updateOk"> OK </v-btn>
                <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- 行追加モーダル -->
          <v-dialog v-model="isOpenShippingAcheiveDialog" :max-width="2000" persistent>
            <DetailListDialog
              :isOpenDetailDialog.sync="isOpenShippingAcheiveDialog"
              :viewDataList="changeList"
              :listLength="totalRecord"
              v-on:child-event="parentMethod"
              :warehouseList="warehouseList"
              :suppliersNm="searchSuppliersSelected"
              :suppliersSelected="suppliersSelected"
              :arrivalScheduleDate="shippingScheduleDate"
              :screenId="screenId"
            />
          </v-dialog>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            disable-sort
            :hide-default-footer="true"
            height="600px"
          >
            <template v-slot:[`item.productCd`]="{ item, index }" style="padding: 0 5px">
              <div
                id="table-productCd"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
                class="listNo-style"
                style="text-align: left; font-size: 16px"
              >
                <div>{{ item.itemCdDisplay }}</div>
                <div>{{ item.itemCdDisplay2 }}</div>
                <div style="text-align: left">({{ item.itemName }})</div>
              </div>
              <div v-else>
                <!-- 商品コード/品名-->
                <v-autocomplete
                  v-model="item.productCd"
                  :items="item.productList"
                  @change="(event) => changeProductNm(event, item, index)"
                  :hint="checkProductCd(item, index)"
                  persistent-hint
                  :search-input.sync="item.search"
                  dense
                  :ref="'cursorProcd' + index"
                  :rules="[
                    $inputRules.isRequiredRow(headerItems, item),
                    $inputRules.isCreatedRowRequired(item.outListSubSid, item.productCd),
                  ]"
                  :error-messages="item.requiredItemCheckMsg"
                >
                  <option disabled value="item.productCd"></option>
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>
            </template>

            <template v-slot:[`item.productQty`]="{ item, index }">
              <div
                id="table-productQty"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              >
                {{ item.productQty }}
              </div>
              <div v-else>
                <!-- 出荷予定数-->
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.productQty"
                  @change="changeQty(item, index)"
                  @click="clickPop(item.productQty)"
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'productQtyCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
            </template>
            <!-- ロット-->
            <template v-slot:[`item.lot`]="{ item }">
              <div
                id="table-lot"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
                class="listNo-style"
              >
                {{ item.lot }}
              </div>
              <div v-else>
                <!-- DOT-->
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lot"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotFlg != '1'"
                />
              </div>
            </template>
            <!-- S/N-->
            <template v-slot:[`item.serial`]="{ item, index }">
              <div
                id="table-serial"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
                class="listNo-style"
              >
                {{ item.serial }}
              </div>
              <div v-else>
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.serial"
                  maxlength="32"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.serialCheck(
                      item.serial,
                      changeList,
                      index + (page - 1) * itemsPerPage,
                      item.productCd
                    ),
                  ]"
                  :disabled="item.serialFlg != '1'"
                />
              </div>
            </template>

            <!-- 期限日 -->
            <template v-slot:[`item.dueDate`]="{ item }">
              <div
                id="table-lot"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
                class="listNo-style"
              >
                {{ item.dueDate }}
              </div>
              <div v-else class="listNo-style">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.dueDate"
                  maxlength="8"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.isNumber]"
                  :disabled="item.dueDateFlg != '1'"
                />
              </div>
            </template>

            <template v-slot:[`item.caseSu`]="{ item, index }">
              <div
                id="table-caseSu"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              >
                {{ item.caseSu }}
              </div>
              <div v-else>
                <!-- ケース数-->
                <v-text-field
                  outlined
                  dense
                  v-model="item.caseSu"
                  class="input-number"
                  :hint="setHintCase(item, index)"
                  @change="changeCase(item, index)"
                  @click="clickPop(item.caseSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'caseSuCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
            </template>

            <template v-slot:[`item.ballSu`]="{ item, index }">
              <div
                id="table-ballSu"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              >
                {{ item.ballSu }}
              </div>
              <div v-else>
                <!-- ポール数-->
                <v-text-field
                  outlined
                  dense
                  v-model="item.ballSu"
                  class="input-number"
                  :hint="setHintBall(item, index)"
                  @change="changeBall(item, index)"
                  @click="clickPop(item.ballSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'ballSuCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
            </template>

            <template v-slot:[`item.baraSu`]="{ item, index }">
              <div
                id="table-baraSu"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              >
                {{ item.baraSu }}
              </div>
              <div v-else>
                <!-- バラ数-->
                <v-text-field
                  outlined
                  dense
                  v-model="item.baraSu"
                  class="input-number"
                  :hint="setHintBara(item, index)"
                  @change="changeBara(item, index)"
                  @click="clickPop(item.baraSu)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'baraSuCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
            </template>
            <template v-slot:[`item.warehouse`]="{ item, index }">
              <div
                id="table-baraSu"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              >
                {{ item.officeNameShort }}
              </div>
              <div v-else>
                <!-- 倉庫-->
                <v-autocomplete
                  v-model="item.warehouse"
                  :items="item.warehouseList"
                  :hint="checkWarehouse(item)"
                  persistent-hint
                  dense
                  @change="(event) => changeWarehouse(event, index)"
                  :error-messages="item.warehouseCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <template v-slot:[`item.location`]="{ item, index }">
              <div
                id="table-baraSu"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              >
                {{ item.location }}
              </div>
              <div v-else>
                <!-- ロケ-->
                <v-autocomplete
                  v-model="item.locationSid"
                  :items="item.locationList"
                  :hint="checkLocation(item, index)"
                  @change="(event) => changeLocation(event, item, index)"
                  :search-input.sync="item.locaSearch"
                  persistent-hint
                  dense
                  :error-messages="item.locationCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>
            </template>

            <template v-slot:[`item.deleteData`]="{ item }">
              <div
                id="table-deleteItem"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
              ></div>
              <div v-else>
                <!-- 削除-->
                <v-btn small @click="deleteItem(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:[`item.accInfos`]="{ item }">
              <div id="table-achieveAdd" v-if="kariFlg">
                <!-- 一旦非表示とする-->
              </div>
              <div v-else>
                <!-- 付帯情報/ロケーション予約 -->
                <v-btn small @click="openAccData(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.accInfos }}
                    </div>
                  </template>
                  <span>{{ item.accInfos }}</span>
                </v-tooltip>
              </div>
            </template>

            <!-- 付属キー１ -->
            <template v-slot:[`item.lotSubkey1`]="{ item }">
              <div
                id="table-lot"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
                class="listNo-style"
              >
                {{ item.lotSubkey1 }}
              </div>
              <div v-else class="listNo-style">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lotSubkey1"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotSubkey1Flg != '1'"
                />
              </div>
            </template>

            <!-- 付属キー２ -->
            <template v-slot:[`item.lotSubkey2`]="{ item }">
              <div
                id="table-lot"
                v-if="
                  item.completeFlg == '1' ||
                  item.isAllOrder > '0' ||
                  (item.isAllOrder > '1' && item.isOrder != '0') ||
                  (item.isAllOrder > '1' && item.isOrder == '0')
                "
                class="listNo-style"
              >
                {{ item.lotSubkey2 }}
              </div>
              <div v-else class="listNo-style">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lotSubkey2"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.lotSubkey2Flg != '1'"
                />
              </div>
            </template>
          </v-data-table>
          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(totalPage)"
              :total-visible="7"
            ></v-pagination>
          </div>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px">
        <v-data-table
          id="listSumDataSpecial"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          class="tableData"
          fixed-header
          :items="inputSumList"
          :headers="headerItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          hide-default-header
          height="49px"
        >
        </v-data-table>
      </v-container>

      <!-- 送り先登録ポップアップ-->
      <v-dialog v-model="dialogDeliveryAdress" @close="close" :max-width="1000">
        <v-card id="dialogDelivery">
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_popDeliveryAddress") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-form ref="destinationList" lazy-validation>
                <v-row>
                  <span class="lbl-screen-name">・届け先</span>
                </v-row>
                <v-row>
                  <!--届け先-名称-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="destination"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_deliveryname')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--届け先-名称-->
                  <span class="require">*</span>
                </v-row>
                <v-row>
                  <!--届け先-郵便番号-->
                  <div class="add-textbox-post">
                    <v-text-field
                      outlined
                      dense
                      maxlength="7"
                      v-model="destinationPostCode"
                      :rules="[rules.inputRequired, rules.isNumber, rules.limiNumLenght7]"
                      :label="$t('label.lbl_postCd')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--届け先-郵便番号-->
                  <span class="require">*</span>
                </v-row>
                <v-row>
                  <!--届け先-住所-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="destinationAddress"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_popAddress')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--届け先-住所-->
                </v-row>
                <v-row>
                  <!--届け先-電話番号-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      maxlength="11"
                      v-model="destinationphoneCode"
                      :rules="[rules.inputRequired, rules.isNumber]"
                      :label="$t('label.lbl_phoneNumber')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--届け先-電話番号-->
                  <span class="require">*</span>
                </v-row>
                <v-row>
                  <!--納品時間-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      maxlength="4"
                      v-model="deliveryDate"
                      :rules="[rules.isTime]"
                      :label="$t('label.lbl_delDateTime')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--納品時間-->
                  <span class="item-spacer" style="float: left">&nbsp;</span>
                  <!--購入金額-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      v-model="purchaseAmount"
                      class="input-number-modal"
                      :rules="[rules.isNumber, rules.limitNumLength11]"
                      :label="$t('label.lbl_purchase')"
                      @click="clickAmount()"
                      @change="changeAmount()"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <!--購入金額-->
                </v-row>
                <v-row>
                  <span class="lbl-screen-name">・発注者</span>
                  <v-btn
                    color="primary"
                    class="api-btn"
                    style="margin-top: 8px"
                    @click="destinationSet"
                  >
                    {{ $t("btn.btn_destination") }}
                  </v-btn>
                </v-row>
                <v-row>
                  <!--発注者-名称-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="ordererName"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_orderName')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-名称-->
                </v-row>
                <v-row>
                  <!--発注者-郵便番号-->
                  <div class="add-textbox-post">
                    <v-text-field
                      outlined
                      dense
                      maxlength="7"
                      v-model="ordererPostCd"
                      :rules="[rules.inputRequired, rules.isNumber, rules.limiNumLenght7]"
                      :label="$t('label.lbl_postCd')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-郵便番号-->
                </v-row>
                <v-row>
                  <!--発注者-住所-->
                  <div class="add-textbox-order-name">
                    <v-text-field
                      outlined
                      dense
                      maxlength="200"
                      v-model="ordererAddress"
                      :rules="[rules.inputRequired]"
                      :label="$t('label.lbl_popAddress')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-住所-->
                </v-row>
                <v-row>
                  <!--発注者-電話番号-->
                  <div class="add-textbox-tel">
                    <v-text-field
                      outlined
                      dense
                      maxlength="11"
                      v-model="orderphoneNumber"
                      :rules="[rules.inputRequired, rules.isNumber]"
                      :label="$t('label.lbl_phoneNumber')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require">*</span>
                  <!--発注者-電話番号-->
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="btn-search-area" style="float: left">
              <v-btn color="primary" class="api-btn" @click="popDeliveryAdressCear">
                {{ "クリア" }}
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="api-btn" @click="popDeliveryAdressClose">
              {{ $t("btn.btn_close") }}
            </v-btn>
            <v-btn
              color="primary"
              class="api-btn"
              :disabled="ableConfirm"
              @click="popDeliveryAdressSave"
            >
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAddProudct" :max-width="800">
        <!-- 付帯情報ポップアップ-->
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleRegistDetail") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-model="location"
                    :label="$t('label.lbl_location')"
                    clear-icon="mdi-close-circle"
                    clearable
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn @click="addLocation()" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-h5"
                  >{{ $t("label.lbl_accessoryInfo") }}
                  <v-btn @click="addAccItem" text>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- 付帯情報入力箇所 -->
              <v-row>
                <v-data-table
                  hide-default-header
                  :headers="accHeader"
                  :items="accList"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.accSelected`]="{ item }">
                    <v-autocomplete
                      v-model="item.accSelected"
                      :items="accKeys"
                      @change="(event) => changeAcc(event, item)"
                      outlined
                      dense
                    ></v-autocomplete>
                    <v-text-field v-show="false" v-model="item.accSelectedNm"></v-text-field>
                  </template>
                  <template v-slot:[`item.accValue`]="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.accValue"
                      maxlength="10"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <v-btn small @click="deleteAccItem(item)" text>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="api-btn" @click="close">
              {{ $t("btn.btn_close") }}
            </v-btn>
            <v-btn color="primary" class="api-btn" @click="save">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 修正登録ボタン押下後エラー時出現エラー -->
      <v-dialog v-model="fixInsertDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :shpSchAddFlg="infoDialog.shpSchAddFlg"
          :shpSchListFlg="infoDialog.shpSchListFlg"
          :firstPageFlag="infoDialog.firstPageFlag"
        />
      </v-dialog>
      <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
          v-on:closePopup="closePopup"
        />
      </v-dialog>

      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDialog.sync="isOpenTruck"
          @click="saveTruck"
          :truckInfo="truckInfo"
          ref="truck"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import Truck from "../../views/common/Truck";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonRules from "@/mixins/CommonRules";
import DetailListDialog from "../../components/DetailListDialog.vue"; // 行追加モーダルダイアログ

export default {
  name: appConfig.SCREEN_ID.P_SHP_004,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    LocationDialog,
    ConfirmDialog,
    Truck,
    NavBar,
    DetailListDialog,
  },
  mixins: [commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },

  data: () => ({
    // 合計リスト
    inputSumList: [],
    // キャッシュ・変更部分登録用リスト
    changeList: [],
    // 一旦非表示するためのフラグ
    kariFlg: true,
    inputProduct: "",
    // 倉庫
    warehouseSelected: "",
    warehouseList: [],
    // ロケ
    locationSelected: "",
    locationList: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    editLocationSid: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // 配送先情報画面に渡す商品コード
    shpProductCd: "",
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shpList: [],
    // 一覧index
    editedIndex: -1,
    // 処理区分
    processDivSelected: "",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    locationMap: {},
    // 輸送会社名（登録用）
    truck: "",
    //個人宛ボタンdisabledフラグ
    individualButton: true,
    // 送り先登録ダイアログ
    dialogDeliveryAdress: false,
    //届け先名(表示用)
    destinationDisplay: "",
    //届け先名(modal)
    destination: "",
    //届け先名(保管)
    shipToName: "",
    //届け先-郵便番号(modal)
    destinationPostCode: "",
    //届け先-郵便番号(保管)
    shipToPostCd: "",
    //届け先-住所(表示用)
    destinationAddressDisplay: "",
    //届け先-住所(modal)
    destinationAddress: "",
    //届け先-住所(保管)
    shipToCharacterName: "",
    //届け先-電話番号(modal)
    destinationphoneCode: "",
    //届け先-電話番号(保管)
    shipToTelephoneNumber: "",
    //納品時間(modal)
    deliveryDate: "",
    //納品時間(保管)
    deliveryTime: "",
    // 納品日必須フラグ
    isDeliveryDateRequireFlg: false,
    //購入金額(modal)
    purchaseAmount: "",
    //購入金額(保管)
    purchasePrice: "",
    //発注者名(modal)
    ordererName: "",
    //発注者名(保管)
    shipFromName: "",
    //発注者-郵便番号(modal)
    ordererPostCd: "",
    //発注者-郵便番号(保管)
    shipFromPostCd: "",
    //発注者-住所(modal)
    ordererAddress: "",
    //発注者-住所(保管)
    shipFromCharacterName: "",
    //発注者-電話番号(modal)
    orderphoneNumber: "",
    //発注者-電話番号(保管)
    shipFromTelephoneNumber: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 修正不可フラグ TRUE：修正不可、FALSE：修正可能
    modifiedFlg: false,
    // 追加ダイアログ
    isOpenShippingAcheiveDialog: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 修正登録時アラートダイアログ
    fixInsertDialogMsg: false,
    //送付先表示用
    deliverystyle: "display: none",
    privateInfSid: "",
    // 付帯情報ダイアログ
    dialogAddProudct: false,
    truckInfo: {
      suppliersSelected: "",
      truckInfo: "",
    },
    // 修正登録フラグ
    addFlg: false,
    // 伝票削除フラグ
    delFlg: false,
    // 入力チェックエラーダイアログ用フラグ
    errorFlg: true,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 出荷No
    txt_slipNo: "",
    // 備考欄
    remarks: "",
    // 有償/無償
    isPaid: "",
    // 責任
    isRes: "",
    isResBadValue: "",
    isResBadCount: 0,
    // 出荷先
    shippingSelected: "",
    // 処理区分
    shippingStatusSelected: "",
    // 出荷ステータスプルダウン
    shippingStatusList: [],
    // 無償理由
    freeReasonSelected: "",
    // 無償理由プルダウン
    freeReasonList: [],
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 出荷予定日付
    shippingScheduleDate: "",
    shippingScheduleDateCal: "",
    shippingScheduleDateMenu: false,
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    // フォーカス移動フラグ
    moveFocusFlag: false,
    simpleDialog: false,
    // ローディング用フラグ
    loadingflg: false,
    // 責任区分活性非活性
    isDisabledBlame: true,
    // お届け先日付
    dateToDelivery: "",
    dateToDeliveryCal: "",
    dateToDeliveryMenu: false,
    // メッセージダイアログ
    infoDialog: {
      title: "",
      message: "",
      outsideClickNotCloseFlg: false,
      shpSchListFlg: false,
      shpSchAddFlg: false,
      homePageFlag: false,
      firstPageFlag: false,
    },
    productListIdx: -1,
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 倉庫未入力エラーメッセージ
    warehouseCheckMsg: "",
    // ロケーション未入力エラーメッセージ
    locationCheckMsg: "",
    // トータル数計算用リスト
    quantityList: [],
    // 有償・無償表示用
    isDisabledFreeReason: "",
    //前画面から受け取る値
    planningSection: "",
    voucherNo: "",
    allocationResult: "",
    searchParam: "",
    toName: "",
    toBaseCd: "",
    // 出荷予定品目一覧ヘッダ
    headerItems: [
      {
        text: "No",
        class: "listHeader arrivalScheduleRegistDetail",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "200px",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },

      {
        text: i18n.tc("label.lbl_shippingProductQty"),
        value: "productQty",
        width: "190px",
        align: "center",
        sortable: false,
        class: "asta",
        isRequired: true,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "180px", align: "center" },
      { text: i18n.tc("label.lbl_serial"), value: "serial", width: "180px", align: "center" },
      {
        // 期限日
        text: i18n.tc("label.lbl_dueDate"),
        value: "dueDate",
        width: "170px",
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_caseSu"),
        value: "caseSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_ballSu"),
        value: "ballSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        text: i18n.tc("label.lbl_baraSu"),
        value: "baraSu",
        width: "180px",
        align: "center",
        class: "asta",
        isRequired: true,
      },
      {
        // 倉庫名
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "135px",
        align: "center",
      },
      {
        // ロケ
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "135px",
        align: "center",
      },
      {
        // 削除
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "74px",
        align: "center",
      },
      {
        // 詳細登録
        text: i18n.tc("label.lbl_arrivalScheduleRegistDetail"),
        value: "accInfos",
        width: "135px",
        class: "arrivalScheduleRegistDetail",
        align: "center",
      },
      {
        // 付属キー１
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "200px",
        align: "center",
      },
      {
        // 付属キー２
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "200px",
        align: "center",
      },
    ],
    // 総件数
    totalCount: 0,
    totalPage: 0,
    totalRecord: 0,
    // ページ差分用
    diff: 0,
    inputList: [],
    page: 1,
    itemsPerPage: 100,
    checkFlg: false,
    // 初期処理用フラグ
    initFlg: true,
    // 有償無償
    freeCostDivList: [],
    // 責任区分
    blameDivList: [],
    // 出荷明細プルダウン
    stockList: [],
    // 削除リスト
    deleteList: [],
    // ボタン活性化
    ableConfirm: true,
    delConfirm: true,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    // 出荷予定日disabledフラグ
    isShippingScheduleDate: false,
    defaultItem: {
      No: "",
      accInfos: "",
      productNm: "",
      productCd: "",
      productHint: "",
      productList: [],
      productAcc: "",
      productQty: "",
      caseSu: "",
      caseSuHint: "",
      ballSu: "",
      ballSuHint: "",
      baraSu: "",
      baraSuHint: "",
      lot: "",
      lotFlg: "0",
      serial: "",
      serialFlg: "0",
      dueDate: "",
      dueDateFlg: "0",
      lotSubkey1: "",
      lotSubkey1Flg: "0",
      lotSubkey2: "",
      lotSubkey2Flg: "0",
      location: "",
      locationSid: "",
      // warehouse: { warehouse: "", location: "", productCd: "", qty: "" },// 詳細登録POPUP表示用
      accInfoList: [],
      search: "",
      warehouse: "",
      warehouseList: [],
      locationList: [],
      locationHint: "",
      locaSearch: "",
      isAdd: true,
    },
    // 品番 品名プルダウン
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accList: [],
    accKeys: [],
    location: "",
    locationSidList: [],
    //検索に使う予定一覧のデータ
    shpInputDataList: [],
    inputListItem: {},
    // 出荷先一覧
    shippingList: [],
    individualList: [],
    getShippingSelected: "",
    // 出荷先ドロップダウンヒント
    shippingHint: "",
    // 出荷先ドロップダウンの活性制御フラグ
    isDisableShippingDropdown: true,
    screenId: "P_SHP_004",
    // 取引先会社SID
    supliersCompSid: "",
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isNumber: (value) =>
        value == "" ||
        value == null ||
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isTime: (value) =>
        value == null ||
        value == "" ||
        value.match("^([0-1][0-9]|2[0-3])[0-5][0-9]$") ||
        i18n.tc("check.chk_inputTime"),
      limitNumLength11: (value) =>
        String(value).replaceAll(",", "").length < 12 || i18n.tc("check.chk_limitLength_11"),
      limiNumLenght7: (value) => String(value).length == 7 || i18n.tc("check.chk_inputPostCd"),
      limitNumLength: (value) =>
        String(value).replaceAll(",", "").length < 10 || i18n.tc("check.chk_limitNumLength"),
      isRangeCheck: (value) => {
        if (value == "" || value == null) {
          // 値が入っていない場合はチェックなし
          return true;
        } else {
          // 処理なし
        }
        if (0 < Number(commonUtil.zen2han(value).replaceAll(",", ""))) {
          return true;
        } else {
          return i18n.tc("check.chk_inputMore");
        }
      },
      isRangeCheckBara: (value) =>
        0 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMoreOne"),
      yyyymmdd_create: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      serialCheck: (value, list, index, value1) => {
        if (value != null && value != undefined && value != "") {
          for (var i = 0; i < list.length; i++) {
            if (index != i) {
              if (list[i].productCd == value1 && list[i].serial == value) {
                return i18n.tc("check.chl_input_serial");
              }
            }
          }
        }
        return true;
      },
    },

    toPage: "",
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      // フォーカス移動
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // //前画面からの値(実装)
    if (this.$route.params.screenName == appConfig.SCREEN_ID.P_TMP_903) {
      // 検索条件
      this.searchParam = this.$route.params.detailsList.headerList;
      // 取引先SID
      this.searchSuppliersSelected = this.searchParam.searchSuppliersSelected;
      // 取引先名
      this.suppliersSelected = this.$route.params.detailsList.suppliersSelectedName;
      // 伝票SID
      this.shipSid = this.$route.params.detailsList.inOutListSub;
      // 出荷予定日
      this.shippingScheduleDate = this.$route.params.detailsList.inOutScheduleDate;
      this.shippingScheduleDateCal = dateTimeHelper.dateCalc(
        dateTimeHelper.convertJST(this.$route.params.shippingScheduleDate)
      );
      // 伝票No
      this.txt_slipNo = this.$route.params.detailsList.inOutListNo;
      // 品質区分
      this.shippingStatusSelected = this.$route.params.detailsList.arrivalStatusSelected;
      // 責任区分
      this.isRes = this.$route.params.detailsList.isRes;
      if (this.$route.params.detailsList.isRes == "01") {
        this.isResBadValue = "02";
      } else {
        this.isResBadValue = this.$route.params.detailsList.isRes;
        this.isResBadCount++;
      }
      // 有償無償
      this.isPaid = this.$route.params.detailsList.isPaid;
      // 理由区分
      this.freeReasonSelected = this.$route.params.detailsList.freeReasonSelected;
      // 備考欄
      this.remarks = this.$route.params.detailsList.remarksTextbox;
      // 処理区分
      this.processDivSelected = this.$route.params.detailsList.processDiv;
      // 出荷先名
      this.shippingSelected = this.$route.params.detailsList.shippingNm;
      // 出荷先
      this.shippingSelectedHeader = this.$route.params.detailsList.toSid;
      // 出荷先名
      this.toName = this.$route.params.detailsList.toName;
      // 出荷先拠点CD
      this.toBaseCd = this.$route.params.detailsList.toBaseCd;
      // 取引先会社SID
      this.supliersCompSid = this.$route.params.detailsList.officeCd;
      // 取引先リスト
      this.supplierList = this.$route.params.detailsList.supplierList;
      // 輸送会社区分
      this.carrierCompCd = this.$route.params.detailsList.carrierCompCd;
      this.truckKbn = this.$route.params.detailsList.carrierCompCd;
      if (this.carrierCompCd == "04") {
        this.truckNm = this.$route.params.detailsList.deliveryTraderName;
        this.truck = this.$route.params.detailsList.deliveryTraderSid;
      } else {
        this.truckNm = this.$route.params.detailsList.deliveryTraderName;
      }
      //お届け先日
      this.dateToDelivery = this.$route.params.detailsList.deliveryDate;
      //お届け先名
      this.shipToName = this.$route.params.detailsList.shipToName;
      //住所
      this.destinationAddress = this.$route.params.detailsList.shipToAddressDetail;
      //遷移元画面
      this.toPage = this.$route.params.screenName;
      // 出荷先ドロップダウンを初期化
      this.initShippingDropdown();
    } else {
      // 出荷予定APIの検索条件を取得する。
      // 出荷伝票SID
      this.shipSid = this.$route.params.shipSid;

      // 出荷予定が存在していない場合の、ヘッダー部設定値
      // 出荷No
      this.txt_slipNo = this.$route.params.txt_slipNo;
      // 出荷予定日
      this.shippingScheduleDate = this.$route.params.shippingScheduleDate;
      this.shippingScheduleDateCal = dateTimeHelper.dateCalc(
        dateTimeHelper.convertJST(this.$route.params.shippingScheduleDate)
      );
      // 品質区分名
      this.shippingStatusSelected = this.$route.params.shippingStatusSelected;
      // 責任区分
      this.isRes = this.$route.params.isRes;
      if (this.$route.params.isRes == "01") {
        this.isResBadValue = "02";
      } else {
        this.isResBadValue = this.$route.params.isRes;
        this.isResBadCount++;
      }
      // 輸送会社区分
      this.carrierCompCd = this.$route.params.carrierCompCd;
      this.truckKbn = this.$route.params.carrierCompCd;
      if (this.carrierCompCd == "04") {
        this.truckNm = this.$route.params.deliveryTraderName;
        this.truck = this.$route.params.traderSid;
      } else {
        this.truckNm = this.$route.params.truckNmHeader;
      }
      // 有償無償
      this.isPaid = this.$route.params.isPaid;
      // 無償理由
      this.freeReasonSelected = this.$route.params.freeReasonSelected;
      // 備考欄
      this.remarks = this.$route.params.remarks;
      // 処理区分名
      this.processDivSelected = this.$route.params.processDivSelected;
      // 出荷先SID
      this.shippingSelectedHeader = this.$route.params.shippingSelectedHeader;
      // 出荷予定日秒数あり
      this.shippingScheduleDateJ = this.$route.params.shippingScheduleDateJ;

      // 出荷予定一覧「検索条件」の値を保持する。
      // 取引先SID
      this.searchSuppliersSelected = this.$route.params.suppliersSelected;
      // 出荷先SID
      this.searchShippingSelected = this.$route.params.shippingSelected;
      // 出荷日From
      this.searchShippingScheduleDateFrom = this.$route.params.shippingScheduleDateFrom;
      // 出荷日Fromカレンダー
      this.searchShippingScheduleDateFromCal = this.$route.params.shippingScheduleDateFromCal;
      // 出荷日To
      this.searchShippingScheduleDateTo = this.$route.params.shippingScheduleDateTo;
      // 出荷日Toカレンダー
      this.searchShippingScheduleDateToCal = this.$route.params.shippingScheduleDateToCal;
      // 発行条件（送り状）コード値
      this.searchIssueTermsSelected = this.$route.params.issueTermsSelected;
      // 発行条件（荷札）コード値
      this.searchTagTermsSelected = this.$route.params.tagTermsSelected;
      // 輸送会社名
      this.searchTruckNm = this.$route.query.truckNm;
      //作成日From
      this.createDateFrom = this.$route.params.createDateFrom;
      //作成日To
      this.createDateTo = this.$route.params.createDateTo;
      // 画面遷移判断フラグ
      this.checkFlg = this.$route.params.checkFlg;
      // 取引先名
      this.suppliersSelected = this.$route.params.suppliersNm;
      // 出荷先名
      this.shippingSelected = this.$route.params.shippingNm;
      // 取引先会社SID
      this.supliersCompSid = this.$route.params.supliersCompSid;
      // 取引先リスト
      this.supplierList = this.$route.params.supplierList;
      // 出荷先ドロップダウンを初期化
      this.initShippingDropdown();
    }
  },

  methods: {
    /**
     * 初期起動処理です。
     */
    init() {
      // ローディング開始
      this.loadingCounter = 1;
      // コードマスタを取得します。
      this.getMstCode();

      // 出荷予定を取得します。
      this.getApiList();
      // キャッシュ用明細取得
      this.getDetailForCache();
      // 出荷予定明細と倉庫マスタと取引先一覧・出荷先一覧を取得します。
      this.getItems();
    },

    /**
     * 出荷予定明細と倉庫マスタと取引先一覧・出荷先一覧を取得します。
     */
    getItems() {
      // 倉庫マスタを取得します。
      let resultWarehouse = this.getWarehouse();

      // 出荷予定明細を取得します。
      let resultDetail = this.getDetail();

      // 非同期処理を開始します。
      Promise.all([resultWarehouse, resultDetail])
        // 成功時の処理です。
        .then(() => {
          // 結果をログ出力します。
          // console.debug("getItems(jstInScheduleDate) Result", result);

          // 画面表示用の倉庫SIDを設定します。
          this.setDispWarehouseSid("");
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.infoDialog.isOpen = true;

          this.infoDialog.firstPageFlag = true;
        });
    },
    /**
     * 追加モーダルからリスト取得。
     */
    parentMethod: function (isConfig, target) {
      if (isConfig) {
        this.updateDialogMessage = false;
        var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");
        // 追加モーダルのリストをキャッシュに追加
        for (var i = 0; i < target.length; i++) {
          var productQty = commonUtil.zen2han(target[i].productQty).replaceAll(",", "");
          totalNum = Number(totalNum) + Number(productQty);
          this.changeList.push({ ...Object.assign({}, target[i]) });
        }

        // 追加分に応じてページ追加
        if (Math.ceil(this.changeList.length / this.itemsPerPage) > this.totalPage) {
          this.totalPage += 1;
        }

        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
        this.totalRecord = this.changeList.length;

        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
        this.useCache();
        this.$nextTick(() => {
          this.$refs.editedList.validate();
        });
        this.resetGetApi = true;
      }
    },

    /**
     * 出荷予定明細を取得します。
     */
    getDetail() {
      this.inputList = [];

      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 出荷伝票SIDを設定します。
      config.params.outListSid = this.shipSid;

      // 削除フラグを設定します。
      config.params.deleteFlg = "0";

      // 基準日を設定します。
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateJ);

      // ソート順を設定します。
      config.params.reqComSortItem = "out_list_sub_sid asc";
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageIndex = this.page;
      config.params.reqComPageLimit = this.itemsPerPage;

      // Configをログ出力します。
      // console.debug("getDetail() Config", config);

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 出荷予定明細を取得します。
          .secureGet(appConfig.API_URL.BIZ_OUTSCHEUDLES_DTL, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログ出力します。
            // console.debug("getDetail() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // JSONオブジェクトから出荷予定明細を取得します。
            const apiData = jsonData.resIdv.outScheduleDtls;

            if (this.initFlg) {
              this.totalPage = jsonData.resCom.resComPagination.totalPage;
              this.totalRecord = jsonData.resCom.resComPagination.totalRecord;
              this.totalCount = jsonData.resIdv.actualCnt;
            }

            this.quantityList = [];

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 出荷予定明細に対する繰り返し処理です。
              for (var j = 0; j < apiData.length; j++) {
                // 出荷予定明細を取得します。
                let apiDataItem = apiData[j];
                // マップを生成します。
                let inputListItem = { locaSearch: "", locationHint: "", locationList: [] };

                // リストを生成します。
                let list = [];

                var itemCd = apiDataItem.itemCd.substring(0, 50).trim();
                var incidental = apiDataItem.itemCd.slice(-50).trim();

                // リストに商品情報を追加します。
                list.push({
                  text: `${itemCd}` + ` ${incidental}` + `（${apiDataItem.itemName}）`,
                  value: apiDataItem.itemStandardSid,
                  name: apiDataItem.itemName,
                  code: apiDataItem.itemCd,
                  isNew: false,
                  serialFlg: apiDataItem.serialAircraftnumberFlg,
                  lotFlg: apiDataItem.lotAircraftnumberFlg,
                  dueDateFlg: apiDataItem.dueDateFlg,
                  lotSubkey1Flg: apiDataItem.lotSubkey1Flg,
                  lotSubkey2Flg: apiDataItem.lotSubkey2Flg,
                  quantity: apiDataItem.outboundUnitQuantity,
                });

                // マップに商品情報を設定します。
                inputListItem.productList = list;

                // マップにNoを設定します。
                inputListItem.No = j + 1 + this.itemsPerPage * (this.page - 1);

                // マップに出荷伝票明細SIDを設定します。
                inputListItem.outListSubSid = apiDataItem.outListSubSid;

                // マップに出荷伝票明細Noを設定します。
                inputListItem.outListSubNo = apiDataItem.outListSubNo;

                // マップに品番を設定します。
                inputListItem.itemCd = apiDataItem.itemCd;
                inputListItem.itemCdDisplay = apiDataItem.itemCd.substring(0, 50).trim();
                inputListItem.itemCdDisplay2 = apiDataItem.itemCd.slice(-50).trim();

                // マップに品名を設定します。
                inputListItem.itemName = apiDataItem.itemName;

                // マップに商品標準SIDを設定します。
                inputListItem.productCd = apiDataItem.itemStandardSid;

                // マップに品番/品名を設定します。
                inputListItem.productHint =
                  itemCd + " " + incidental + "（" + apiDataItem.itemName + "）";

                // マップに商品検索を設定します。
                inputListItem.search = apiDataItem.itemCd + "（" + apiDataItem.itemName + "）";

                // マップにロットを設定します。
                let lotObj = commonUtil.convertLotNo(apiDataItem.lotNo);
                inputListItem.lot = lotObj.original.lot;
                inputListItem.serial = lotObj.original.serial;
                inputListItem.dueDate = lotObj.original.dueDate;
                inputListItem.lotSubkey1 = lotObj.original.lotSubkey1;
                inputListItem.lotSubkey2 = lotObj.original.lotSubkey2;
                inputListItem.lotFlg = apiDataItem.lotAircraftnumberFlg;
                inputListItem.serialFlg = apiDataItem.serialAircraftnumberFlg;
                inputListItem.dueDateFlg = apiDataItem.dueDateFlg;
                inputListItem.lotSubkey1Flg = apiDataItem.lotSubkey1Flg;
                inputListItem.lotSubkey2Flg = apiDataItem.lotSubkey2Flg;

                // 出荷予定数を取得します。
                let productQtyNum = apiDataItem.shipScheduleQuantity;
                this.quantityList.push(productQtyNum);

                // マップに出荷予定数を設定します。
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );

                // ケース数を取得します。
                let caseNum = apiDataItem.shipPackingQuantity;

                // マップにケース数を設定します。
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));

                // マップにケース数ヒントを設定します。
                inputListItem.caseSuHint = String(commonUtil.formatToCurrency(Number(caseNum)));

                // ボール数を取得します。
                let ballNum = apiDataItem.shipUnitQuantity;

                // マップにボール数を設定します。
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));

                // マップにボール数ヒントを設定します。
                inputListItem.ballSuHint = String(commonUtil.formatToCurrency(Number(ballNum)));

                // バラ数を取得します。
                let baraNum = apiDataItem.fracQuantity;

                // マップにバラ数を設定します。
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップにバラ数ヒントを設定します。
                inputListItem.baraSuHint = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップに明細更新日時を設定します。
                inputListItem.updateDatetime = apiDataItem.updateDatetime;

                // マップに品質区分を設定します。
                inputListItem.qualityDiv = apiDataItem.qualityDiv;

                // マップに品質区分名を設定します。
                inputListItem.qualityDivName = apiDataItem.qualityDivName;

                // マップに引当済のフラグを設定します。
                inputListItem.isOrder = apiDataItem.isOrder;

                // マップに完了フラグを設定します。
                inputListItem.completeFlg = apiDataItem.completeFlg;

                if (apiDataItem.completeFlg == 1) {
                  this.isShippingScheduleDate = true;
                }

                // マップに出庫済ステータスフラグを設定します。
                inputListItem.isAllOrder = apiDataItem.isAllOrder;

                // 付属情報リストを生成します。
                let infoCategoryDtl = [];

                // 付属情報が存在する場合の処理です。
                if (apiDataItem.outScheduleSub) {
                  // 付属情報に対する繰り返し処理です。
                  for (var k = 0; k < apiDataItem.outScheduleSub.length; k++) {
                    // 付属情報マップを生成します。
                    var accInfoMap = {};

                    // 付属情報を取得します。
                    let subItem = apiDataItem.outScheduleSub[k];

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelected = subItem.subInformation;

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelectedNm = subItem.subInformation;

                    // 付属情報マップに付属情報コメントを設定します。
                    accInfoMap.accValue = subItem.subInformationComment;

                    // 付属情報マップを付属情報リストに追加します。
                    infoCategoryDtl.push(accInfoMap);
                  }
                }

                // マップに付属情報リストを設定します。
                inputListItem.accInfoList = infoCategoryDtl;

                // ロケーション予約リストを生成します。
                let locationReserveList = [];

                // ロケーション予約が存在する場合の処理です。
                if (apiDataItem.locationReserve.length != 0) {
                  // ロケーション予約に対する繰り返し処理です。
                  for (var l = 0; l < apiDataItem.locationReserve.length; l++) {
                    // 倉庫マップを生成します。
                    var warehouseMap = {};

                    // ロケーション予約を取得します。
                    let locaItem = apiDataItem.locationReserve[l];

                    // 倉庫マップにロケーションSIDを設定します。
                    warehouseMap.locationSid = locaItem.locationSid;

                    // 倉庫マップにロケーションNoを設定します。
                    warehouseMap.locationNo = locaItem.locationNo;

                    // 倉庫マップをロケーション予約リストに追加します。
                    locationReserveList.push(warehouseMap);
                  }

                  // ロケーションリストを生成します。
                  let listLoca = [];

                  // ロケーションリストに追加します。
                  listLoca.push({
                    // ロケーションNo
                    text: locationReserveList[0].locationNo,

                    // ロケーションSID
                    value: locationReserveList[0].locationSid,

                    // ロケーションNo
                    name: locationReserveList[0].locationNo,
                  });

                  // マップにロケーションリストを設定します。
                  inputListItem.locationList = listLoca;

                  // マップにロケーションSIDを設定します。
                  inputListItem.locationSid = locationReserveList[0].locationSid;

                  // マップにロケーションを設定します。
                  inputListItem.location = locationReserveList[0].locationNo;

                  // マップにロケーションSIDを設定します。
                  inputListItem.locationSid = locationReserveList[0].locationSid;

                  // マップにロケーション検索を設定します。
                  inputListItem.locaSearch = locationReserveList[0].locationNo;

                  // マップにロケーションヒントを設定します。
                  inputListItem.locationHint = locationReserveList[0].locationNo;

                  // マップに倉庫SIDを設定します。
                  inputListItem.warehouseSid = apiDataItem.warehouseSid;
                } else {
                  // LiputListItemにロケ予約情報を追加
                  // inputListItem.location = "";コメントアウトした。詳細登録が復活になったらここもコメント外す
                }

                // マップをinputListに追加します。
                this.inputList.push(inputListItem);

                // 出荷予定明細のインデックスが0の場合の処理です。
                if (j == 0) {
                  // 品質区分名を設定します。
                  this.shippingStatusSelected = apiDataItem.qualityDiv;
                }
              }

              this.isDisableShippingDropdown = this.judgeIsWorkingAtLeastOne();
              // タスクを終了します（成功）。
              resolve(response);
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = false;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          });
        // 成功時の処理です。
      }).then(() => {
        this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
      });
    },

    /**
     * キャッシュ用の出荷予定明細を取得します。
     */
    getDetailForCache() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 出荷伝票SIDを設定します。
      config.params.outListSid = this.shipSid;

      // 削除フラグを設定します。
      config.params.deleteFlg = "0";

      // 基準日を設定します。
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateJ);

      // ソート順を設定します。
      config.params.reqComSortItem = "out_list_sub_sid asc";

      // Configをログ出力します。
      // console.debug("getDetail() Config", config);

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 出荷予定明細を取得します。
          .secureGet(appConfig.API_URL.BIZ_OUTSCHEUDLES_DTL, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログ出力します。
            // console.debug("getDetail() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // JSONオブジェクトから出荷予定明細を取得します。
            const apiData = jsonData.resIdv.outScheduleDtls;
            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 出荷予定明細に対する繰り返し処理です。
              for (var j = 0; j < apiData.length; j++) {
                // 出荷予定明細を取得します。
                let apiDataItem = apiData[j];
                // 修正不可フラグ設定
                if (!this.modifiedFlg) {
                  this.modifiedFlg = apiDataItem.modifiedFlg;
                }
                // マップを生成します。
                let inputListItem = { locaSearch: "", locationHint: "", locationList: [] };

                // リストを生成します。
                let list = [];

                var itemCd = apiDataItem.itemCd.substring(0, 50).trim();
                var incidental = apiDataItem.itemCd.slice(-50).trim();

                // リストに商品情報を追加します。
                list.push({
                  text: `${itemCd}` + ` ${incidental}` + `（${apiDataItem.itemName}）`,
                  value: apiDataItem.itemStandardSid,
                  name: apiDataItem.itemName,
                  code: apiDataItem.itemCd,
                  isNew: false,
                  lotFlg: apiDataItem.lotAircraftnumberFlg,
                  serialFlg: apiDataItem.serialAircraftnumberFlg,
                  dueDateFlg: apiDataItem.dueDateFlg,
                  lotSubkey1Flg: apiDataItem.lotSubkey1Flg,
                  lotSubkey2Flg: apiDataItem.lotSubkey2Flg,
                  quantity: apiDataItem.outboundUnitQuantity,
                });

                // マップに商品情報を設定します。
                inputListItem.productList = list;

                // マップにNoを設定します。
                inputListItem.No = j + 1;

                // マップに出荷伝票明細SIDを設定します。
                inputListItem.outListSubSid = apiDataItem.outListSubSid;

                // マップに出荷伝票明細Noを設定します。
                inputListItem.outListSubNo = apiDataItem.outListSubNo;

                // マップに品番を設定します。
                inputListItem.itemCd = apiDataItem.itemCd;
                inputListItem.itemCdDisplay = apiDataItem.itemCd.substring(0, 50).trim();
                inputListItem.itemCdDisplay2 = apiDataItem.itemCd.slice(-50).trim();

                // マップに品名を設定します。
                inputListItem.itemName = apiDataItem.itemName;

                // マップに商品標準SIDを設定します。
                inputListItem.productCd = apiDataItem.itemStandardSid;

                // マップに品番/品名を設定します。
                inputListItem.productHint =
                  itemCd + " " + incidental + "（" + apiDataItem.itemName + "）";

                // マップに商品検索を設定します。
                inputListItem.search = apiDataItem.itemCd + "（" + apiDataItem.itemName + "）";

                // マップにロットを設定します。
                let lotObj = commonUtil.convertLotNo(apiDataItem.lotNo);
                inputListItem.lot = lotObj.original.lot;
                inputListItem.serial = lotObj.original.serial;
                inputListItem.dueDate = lotObj.original.dueDate;
                inputListItem.lotSubkey1 = lotObj.original.lotSubkey1;
                inputListItem.lotSubkey2 = lotObj.original.lotSubkey2;
                inputListItem.lotFlg = apiDataItem.lotAircraftnumberFlg;
                inputListItem.serialFlg = apiDataItem.serialAircraftnumberFlg;
                inputListItem.dueDateFlg = apiDataItem.dueDateFlg;
                inputListItem.lotSubkey1Flg = apiDataItem.lotSubkey1Flg;
                inputListItem.lotSubkey2Flg = apiDataItem.lotSubkey2Flg;

                // 出荷予定数を取得します。
                let productQtyNum = apiDataItem.shipScheduleQuantity;

                // マップに出荷予定数を設定します。
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );

                // ケース数を取得します。
                let caseNum = apiDataItem.shipPackingQuantity;

                // マップにケース数を設定します。
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));

                // マップにケース数ヒントを設定します。
                inputListItem.caseSuHint = String(commonUtil.formatToCurrency(Number(caseNum)));

                // ボール数を取得します。
                let ballNum = apiDataItem.shipUnitQuantity;

                // マップにボール数を設定します。
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));

                // マップにボール数ヒントを設定します。
                inputListItem.ballSuHint = String(commonUtil.formatToCurrency(Number(ballNum)));

                // バラ数を取得します。
                let baraNum = apiDataItem.fracQuantity;

                // マップにバラ数を設定します。
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップにバラ数ヒントを設定します。
                inputListItem.baraSuHint = String(commonUtil.formatToCurrency(Number(baraNum)));

                // マップに明細更新日時を設定します。
                inputListItem.updateDatetime = apiDataItem.updateDatetime;

                // マップに品質区分を設定します。
                inputListItem.qualityDiv = apiDataItem.qualityDiv;

                // マップに品質区分名を設定します。
                inputListItem.qualityDivName = apiDataItem.qualityDivName;

                // マップに引当済のフラグを設定します。
                inputListItem.isOrder = apiDataItem.isOrder;

                // マップに完了フラグを設定します。
                inputListItem.completeFlg = apiDataItem.completeFlg;

                if (apiDataItem.completeFlg == 1) {
                  this.isShippingScheduleDate = true;
                }

                // マップに出庫済ステータスフラグを設定します。
                inputListItem.isAllOrder = apiDataItem.isAllOrder;

                // 付属情報リストを生成します。
                let infoCategoryDtl = [];

                // 付属情報が存在する場合の処理です。
                if (apiDataItem.outScheduleSub) {
                  // 付属情報に対する繰り返し処理です。
                  for (var k = 0; k < apiDataItem.outScheduleSub.length; k++) {
                    // 付属情報マップを生成します。
                    var accInfoMap = {};

                    // 付属情報を取得します。
                    let subItem = apiDataItem.outScheduleSub[k];

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelected = subItem.subInformation;

                    // 付属情報マップに付属情報を設定します。
                    accInfoMap.accSelectedNm = subItem.subInformation;

                    // 付属情報マップに付属情報コメントを設定します。
                    accInfoMap.accValue = subItem.subInformationComment;

                    // 付属情報マップを付属情報リストに追加します。
                    infoCategoryDtl.push(accInfoMap);
                  }
                }

                // マップに付属情報リストを設定します。
                inputListItem.accInfoList = infoCategoryDtl;

                // ロケーション予約リストを生成します。
                let locationReserveList = [];

                // ロケーション予約が存在する場合の処理です。
                if (apiDataItem.locationReserve.length != 0) {
                  // ロケーション予約に対する繰り返し処理です。
                  for (var l = 0; l < apiDataItem.locationReserve.length; l++) {
                    // 倉庫マップを生成します。
                    var warehouseMap = {};

                    // ロケーション予約を取得します。
                    let locaItem = apiDataItem.locationReserve[l];

                    // 倉庫マップにロケーションSIDを設定します。
                    warehouseMap.locationSid = locaItem.locationSid;

                    // 倉庫マップにロケーションNoを設定します。
                    warehouseMap.locationNo = locaItem.locationNo;

                    // 倉庫マップをロケーション予約リストに追加します。
                    locationReserveList.push(warehouseMap);
                  }

                  // ロケーションリストを生成します。
                  let listLoca = [];

                  // ロケーションリストに追加します。
                  listLoca.push({
                    // ロケーションNo
                    text: locationReserveList[0].locationNo,

                    // ロケーションSID
                    value: locationReserveList[0].locationSid,

                    // ロケーションNo
                    name: locationReserveList[0].locationNo,
                  });

                  // マップにロケーションリストを設定します。
                  inputListItem.locationList = listLoca;

                  // マップにロケーションSIDを設定します。
                  inputListItem.locationSid = locationReserveList[0].locationSid;

                  // マップにロケーションを設定します。
                  inputListItem.location = locationReserveList[0].locationNo;

                  // マップにロケーションSIDを設定します。
                  inputListItem.locationSid = locationReserveList[0].locationSid;

                  // マップにロケーション検索を設定します。
                  inputListItem.locaSearch = locationReserveList[0].locationNo;

                  // マップにロケーションヒントを設定します。
                  inputListItem.locationHint = locationReserveList[0].locationNo;

                  // マップに倉庫SIDを設定します。
                  inputListItem.warehouseSid = apiDataItem.warehouseSid;
                } else {
                  // LiputListItemにロケ予約情報を追加
                  // inputListItem.location = "";コメントアウトした。詳細登録が復活になったらここもコメント外す
                }

                // マップをchangeListに追加します。
                this.changeList.push(inputListItem);

                // 出荷予定明細のインデックスが0の場合の処理です。
                if (j == 0) {
                  // 品質区分名を設定します。
                  this.shippingStatusSelected = apiDataItem.qualityDiv;
                }
              }
              this.isDisableShippingDropdown = this.judgeIsWorkingAtLeastOne();
              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          })
          // 成功・失敗に関わらず実行される処理です。
          .finally(() => {
            // ローディング画面の表示をOFFにします。
            this.loadingCounter = 0;
            this.loadingflg = true;
          });
        // 成功時の処理です。
      }).then(() => {
        var cut = 0;
        // ボタン活性非活性判定
        if (this.modifiedFlg) {
          // 修正不可フラグがtrueの場合
          this.isDoAddButton = true;
          this.ableConfirm = true;
          this.delConfirm = true;
        } else {
          if (this.changeList.length == cut) {
            // 全て引当済OR全て出庫実績登録済（全てのボタン非活性）
            this.ableConfirm = true;
            this.delConfirm = true;
            this.isDoAddButton = true;
          }
          for (var m = 0; m < this.changeList.length; m++) {
            if (this.changeList[m].completeFlg == "1") {
              // 出荷済
              if (this.ableConfirm) {
                // 伝票自体、完了している
              } else {
                // 伝票は完了していないが、一部明細が完了の場合、伝票削除ボタンだけ非活性
                this.ableConfirm = false;
                this.delConfirm = true;
                this.isDoAddButton = false;
              }
            } else if (
              this.changeList[m].isAllOrder > 0 ||
              (this.changeList[m].isAllOrder > 1 && this.changeList[m].isOrder != 0) ||
              (this.changeList[m].isAllOrder > 1 && this.changeList[m].isOrder == 0)
            ) {
              // 伝票は完了していないが、一部明細が完了している場合、伝票削除ボタンだけ非活性
              this.ableConfirm = false;
              this.delConfirm = true;
              this.isDoAddButton = false;
              cut = cut + 1;
            } else {
              // 未引当かつ、実績もない場合
              this.ableConfirm = false;
              this.delConfirm = false;
              this.isDoAddButton = false;
            }
          }
          if (this.changeList.length == cut) {
            // 全て引当済OR全て出庫実績登録済（全てのボタン非活性）
            this.ableConfirm = true;
            this.delConfirm = true;
            this.isDoAddButton = true;
          } else if (cut > 0) {
            this.ableConfirm = false;
            this.delConfirm = true;
            this.isDoAddButton = false;
          }
        }
      });
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      this.truckNm = this.truckInfo.carrierCompName;
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.isOpenTruck = false;
    },

    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      this.truckInfo.suppliersSelected = this.searchSuppliersSelected;
      //路線業者SID
      this.truckInfo.deliveryTraderSid = this.truck;
      //輸送業者コード
      this.truckInfo.carrierCompCd = this.truckKbn;
      this.isOpenTruck = true;
      if (this.$refs.truck != undefined) {
        //表示時処理
        this.$refs.truck.init();
      }
    },

    /**
     * 画面表示用の倉庫SIDを設定します。
     */
    setDispWarehouseSid() {
      // inputListに対する繰り返し処理です。
      for (let i = 0; i < this.inputList.length; i++) {
        // 倉庫SIDが設定されている場合の処理です。
        if (
          this.inputList[i].warehouseSid != undefined &&
          this.inputList[i].warehouseSid != null &&
          this.inputList[i].warehouseSid != ""
        ) {
          // マップに倉庫リストを設定します。
          this.inputList[i].warehouseList = this.warehouseList;

          // マップに倉庫SIDを設定します。
          this.$set(this.inputList[i], "warehouseSid", this.inputList[i].warehouseSid);

          // 倉庫リストに対する繰り返し処理です。
          for (let k = 0; k < this.warehouseList.length; k++) {
            // 倉庫SIDが一致する場合の処理です。
            if (this.inputList[i].warehouseSid == this.warehouseList[k].value) {
              // マップに倉庫名称略称を設定します。
              this.$set(this.inputList[i], "officeNameShort", this.warehouseList[k].nameShort);
            }
          }

          // マップに倉庫SIDを設定します。
          this.inputList[i].warehouse = this.inputList[i].warehouseSid;
        } else {
          // 倉庫SIDが設定されていない場合の処理です。
          // マップに倉庫リストを設定します。
          this.$set(this.inputList[i], "warehouseList", this.warehouseList);
        }
      }

      // inputListをログに出力します。
      // console.debug("this.inputList", this.inputList);
    },

    /**
     * キャッシュ用の倉庫SIDを設定します。
     */
    setDispWarehouseSidForCache() {
      // changeListに対する繰り返し処理です。
      for (let i = 0; i < this.changeList.length; i++) {
        // 倉庫SIDが設定されている場合の処理です。
        if (
          this.changeList[i].warehouseSid != undefined &&
          this.changeList[i].warehouseSid != null &&
          this.changeList[i].warehouseSid != ""
        ) {
          // マップに倉庫リストを設定します。
          this.changeList[i].warehouseList = this.warehouseList;

          // マップに倉庫SIDを設定します。
          this.$set(this.changeList[i], "warehouseSid", this.changeList[i].warehouseSid);

          // 倉庫リストに対する繰り返し処理です。
          for (let k = 0; k < this.warehouseList.length; k++) {
            // 倉庫SIDが一致する場合の処理です。
            if (this.changeList[i].warehouseSid == this.warehouseList[k].value) {
              // マップに倉庫名称略称を設定します。
              this.$set(this.changeList[i], "officeNameShort", this.warehouseList[k].nameShort);
            }
          }

          // マップに倉庫SIDを設定します。
          this.changeList[i].warehouse = this.changeList[i].warehouseSid;
        } else {
          // 倉庫SIDが設定されていない場合の処理です。
          // マップに倉庫リストを設定します。
          this.$set(this.changeList[i], "warehouseList", this.warehouseList);
        }
      }

      // inputListをログに出力します。
      // console.debug("this.inputList", this.inputList);
    },

    /**
     * ロケAPI接続
     */
    getLocation(locationSid, warehouseSid) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      if (locationSid == "" || locationSid == null) {
        // 処理なし
      } else {
        config.params.locationSid = locationSid;
      }

      if (warehouseSid == "" || warehouseSid == null) {
        // 処理なし
      } else {
        config.params.warehouseSid = warehouseSid;
      }

      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // ソート順
      config.params.reqComSortItem = "location_sid asc";

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateJ);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATION, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  locationNo: row.locationNo,
                  locationSid: row.locationSid,
                  officeNameShort: row.officeNameShort,
                  warehouseSid: row.warehouseSid,
                });
              });
              this.locationSidList = list;
              for (let i = 0; i < this.locationSidList; i++) {
                // ロケ名取得
                this.location = this.locationSidList[0].text;
              }

              resolve(list);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          });
      });
    },

    /**
     * 出荷予定を取得します。
     */
    getApiList() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 営業所SIDを設定します。
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // 取引先SIDを設定します。
      config.params.clientSid = this.searchSuppliersSelected;

      // 出荷伝票SIDを設定します。
      config.params.outListSid = this.shipSid;

      // Configをログ出力します。
      // console.debug("getApiList() Config", config);

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 出荷予定を取得します。
          .secureGet(appConfig.API_URL.BIZ_OUTSCHEDULES, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログ出力します。
            // console.debug("getApiList() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // リストを生成します。
              const list = [];

              // JSONオブジェクトに対する繰り返し処理です。
              jsonData.resIdv.outSchedules.forEach((row) => {
                // リストにハッシュを追加します。
                list.push({
                  // 出荷日（API登録用=UTC）
                  shippingScheduleDateJ: row.outScheduleDate,
                  // 出荷日（画面表示用=JST:秒数なし）
                  shippingScheduleDate: dateTimeHelper
                    .convertUTC2JST(row.outScheduleDate)
                    .substr(0, 10),
                  // 出荷伝票SID
                  shipSid: row.outListSid,
                  // 出荷伝票No
                  shipNo: row.outListNo,
                  // toSid
                  toSid: row.toSid,
                  // 有償無償区分
                  isPaid: row.freeCostDiv,
                  // 処理区分
                  processingdivision: row.processDiv,
                  // 処理区分名
                  processingdivisionNm: row.processDivName,
                  // 出荷先SID
                  shippingNm: row.toSid,
                  // 輸送会社名
                  carrierCompName: row.carrierCompCdName,
                  // 輸送会社区分
                  carrierCompCd: row.carrierCompCd,
                  // 路線業者SID
                  traderSid: row.deliveryTraderSid,
                  // 路線業者名
                  deliveryTraderName: row.deliveryTraderName,
                  // 備考
                  remarks: row.remarks,
                  // 責任区分
                  blameDiv: row.blameDiv,
                  // 無償理由CD
                  reasonCd: row.reasonCd,
                  // 郵便番号
                  postCd: row.postCd,
                  // 送り先名
                  sendName: row.sendName,
                  // 都道府県
                  prefectures: row.prefectures,
                  // 市区町村
                  municipalities: row.municipalities,
                  // 字名
                  characterName: row.characterName,
                  // 電話番号
                  telephoneNumber: row.telephoneNumber,
                  // FAX番号
                  faxNumber: row.faxNumber,
                  // 送り状発行日
                  invoicePrintDate: row.invoicePrintDate,
                  // 荷札発行日
                  taglabelPrintDate: row.taglabelPrintDate,
                  // 理由CD名
                  reasonCdName: row.reasonCdName,
                  // 更新日時
                  updateDatetime: row.updateDatetime,
                  // 完了フラグ
                  completeFlg: row.completeFlg,
                  // 引当済フラグ
                  isOutOrder: row.isOutOrder,
                  // お届け予定日
                  dateToDelivery: row.deliveryDate,
                  dateToDeliveryCal: row.deliveryDate,
                  // 更新・削除日時
                  additionalInfoUpdateDatetime: row.additionalInfoUpdateDatetime,
                  //個人企業識別フラグ
                  privateIdentityFlg: row.privateIdentityFlg,
                  //発注者情報
                  shipFromPostCd: row.shipFromPostCd,
                  shipFromName: row.shipFromName,
                  shipFromAddressDetail: row.shipFromAddressDetail,
                  shipFromTelephoneNumber: row.shipFromTelephoneNumber,
                  //発注者情報
                  deliveryTime: row.deliveryTime,
                  purchasePrice: row.purchasePrice,
                  //届け先情報
                  shipToName: row.shipToName,
                  shipToPostCd: row.shipToPostCd,
                  shipToAddressDetail: row.shipToAddressDetail,
                  shipToTelephoneNumber: row.shipToTelephoneNumber,
                  //届け先情報
                  privateInfSid: row.privateInfSid,
                });
              });

              // リストを検索に使う予定一覧に設定します。
              this.shpInputDataList = list;

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          });
        // 成功時の処理です。
      })
        .then(() => {
          // 検索に使う予定一覧の要素数が0より大きい場合の処理です。
          if (this.shpInputDataList.length > 0) {
            // 出荷予定修正削除画面の検索条件に値を設定します。
            // 出荷No.
            this.txt_slipNo = this.shpInputDataList[0].shipNo;
            // 出荷予定日（秒数なし）
            this.shippingScheduleDate = this.shpInputDataList[0].shippingScheduleDate;
            // 責任区分
            this.isRes = this.shpInputDataList[0].blameDiv;
            this.truckKbn = this.shpInputDataList[0].carrierCompCd;
            if (this.shpInputDataList[0].carrierCompCd == "04") {
              // 輸送会社区分が04（グループ会社）であった場合、路線業者を設定
              this.truckNm = this.shpInputDataList[0].deliveryTraderName;
              this.truck = this.shpInputDataList[0].traderSid;
            } else {
              // 輸送会社
              this.truckNm = this.shpInputDataList[0].carrierCompName;
            }
            // 有償無償
            this.isPaid = this.shpInputDataList[0].isPaid;
            // 無償理由
            this.freeReasonSelected = this.shpInputDataList[0].reasonCd;
            // 備考欄
            this.remarks = this.shpInputDataList[0].remarks;
            // 処理区分名
            this.processDivSelected = this.shpInputDataList[0].processingdivisionNm;
            // 出荷先SID
            this.toSidDataList = this.shpInputDataList[0].toSid;
            // 出荷予定日（秒数あり）
            this.shippingScheduleDateJ = this.shpInputDataList[0].shippingScheduleDateJ;
            // お届け予定日
            if (
              this.shpInputDataList[0].dateToDelivery === null ||
              this.shpInputDataList[0].dateToDelivery === "" ||
              this.shpInputDataList[0].dateToDelivery === undefined
            ) {
              this.dateToDelivery = "";
              this.dateToDeliveryCal = "";
            } else {
              this.dateToDelivery = this.shpInputDataList[0].dateToDelivery;
              this.dateToDeliveryCal = dateTimeHelper.dateCalc(
                this.shpInputDataList[0].dateToDeliveryCal
              );
            }
            //個人宅の場合
            if (this.shpInputDataList[0].privateIdentityFlg == "1") {
              this.individualButton = false;
              //発注者情報
              this.shipFromPostCd = this.shpInputDataList[0].shipFromPostCd;
              this.shipFromName = this.shpInputDataList[0].shipFromName;
              this.shipFromCharacterName = this.shpInputDataList[0].shipFromAddressDetail;
              this.shipFromTelephoneNumber = this.shpInputDataList[0].shipFromTelephoneNumber;
              //発注者情報
              this.deliveryTime = this.shpInputDataList[0].deliveryTime;
              if (this.shpInputDataList[0].purchasePrice != "") {
                this.purchasePrice = commonUtil.formatToCurrency(
                  Number(this.shpInputDataList[0].purchasePrice)
                );
              }
              //届け先情報
              this.shipToName = this.shpInputDataList[0].shipToName;
              this.shipToPostCd = this.shpInputDataList[0].shipToPostCd;
              this.shipToCharacterName = this.shpInputDataList[0].shipToAddressDetail;
              this.shipToTelephoneNumber = this.shpInputDataList[0].shipToTelephoneNumber;
              //届け先情報
              this.destinationAddressDisplay = this.shpInputDataList[0].shipToAddressDetail;
              this.destinationDisplay = this.shpInputDataList[0].shipToName;
              //3行目表示
              this.deliverystyle = "display: block";
            }
            this.privateInfSid = this.shpInputDataList[0].privateInfSid;
            // ボタンの判定(1:完了しているもの)
            if (this.shpInputDataList[0].completeFlg == 1) {
              this.isDoAddButton = true;
              this.ableConfirm = true;
              this.delConfirm = true;
            }
          } else {
            // 処理なし
          }
        })
        .then(() => {
          // PC画面制御
          const functionPermissions = getParameter.getFunctionPermissions({
            conditions: [
              {
                clientSid: this.searchSuppliersSelected,
                attachResourceKey: appConfig.MENU_ID.P_SHP_SCHEDULE_ADD,
                item01: "DeliveryDate",
              },
            ],
          });
          Promise.all([functionPermissions])
            .then((result) => {
              if (result[0].find((permission) => permission.item01 == "DeliveryDate")) {
                this.isDeliveryDateRequireFlg = true;
              } else {
                this.isDeliveryDateRequireFlg = false;
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            });
        });
    },

    //購入金額：カンマセット処理
    changeAmount() {
      this.purchaseAmount = commonFunction.getDelimiterFew(this.purchaseAmount, 11, 0);
    },

    //購入金額：カンマ削除処理
    clickAmount() {
      this.purchaseAmount = commonFunction.getCommaRemoval(this.purchaseAmount);
    },

    /**
     * 出荷予定修正削除画面：戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 出荷予定修正削除画面：一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        if (this.toPage) {
          this.$router.push({
            name: this.toPage,
            params: {
              headerList: this.searchParam,
              backPage: this.toPage,
            },
          });
          return;
        }

        this.checkFlg = true;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_SHP_003,
          params: {
            // 出荷予定一覧「検索条件」の値を保持する。
            // 取引先SID
            searchSuppliersSelected: this.searchSuppliersSelected,
            // 出荷先SID
            searchShippingSelected: this.searchShippingSelected,
            // 出荷日From
            searchShippingScheduleDateFrom: this.searchShippingScheduleDateFrom,
            // 出荷日Fromカレンダー
            searchShippingScheduleDateFromCal: this.searchShippingScheduleDateFromCal,
            // 出荷日To
            searchShippingScheduleDateTo: this.searchShippingScheduleDateTo,
            // 出荷日Toカレンダー
            searchShippingScheduleDateToCal: this.searchShippingScheduleDateToCal,
            // 発行条件（送り状）コード値
            searchIssueTermsSelected: this.searchIssueTermsSelected,
            // 発行条件（荷札）コード値
            searchTagTermsSelected: this.searchTagTermsSelected,
            //作成日From
            createDateFrom: this.createDateFrom,
            //作成日To
            createDateTo: this.createDateTo,
            // 輸送会社名
            searchTruckNm: this.searchTruckNm,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 出荷先一覧
            shippingList: this.$route.params.shippingList,
            // 出荷先名表示用
            shippingNm: this.$route.params.shippingOmitNm,
            // 検索条件
            searchParam: this.$route.params.searchParam,
          },
        });
        this.updateDialogMessage = false;
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 倉庫変更時、ロケ初期化
     */
    changeWarehouse(item, index) {
      this.inputList[index].location = "";
      this.inputList[index].locationSid = null;
      this.inputList[index].locationHint = "";
      this.inputList[index].locationList = [];

      if (this.inputList[index].warehouse == "" || this.inputList[index].warehouse == null) {
        this.inputList[index].warehouse = "";
        this.inputList[index].warehouseSid = null;
        this.inputList[index].warehouseHint = "";
      }

      // ロケーションを再取得する
      // let locationList = this.getLocation(null, item);
      // Promise.all([locationList]).then((result) => {
      //   for (let i = 0; i < result[0].length; i++) {
      //     let locationInfo = {};
      //     locationInfo.text = result[0][i].locationNo;
      //     locationInfo.value = result[0][i].locationSid;
      //     locationInfo.name = result[0][i].locationNo;
      //     this.inputList[index].locationList.push(locationInfo);
      //   }
      // });
    },

    /**
     * 出荷予定修正削除画面：伝票削除ボタン押下
     */
    shpScheDel() {
      let cnt = 0;

      // 明細部、入力チェック実施
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].productCd) {
          var checkQtyNum = this.inputList[i].productQty;
          var checkCaseNum = this.inputList[i].caseSu;
          var checkBallNum = this.inputList[i].ballSu;
          var checkBaraNum = this.inputList[i].baraSu;

          if (this.inputList[i].productQty == "") {
            // 出荷予定数が未入力時
            cnt = i + 1;
          } else if (this.inputList[i].productQty == 0) {
            // 出荷予定数が0の場合、警告メッセージ表示
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-001_005_E");
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkQtyNum).replaceAll(",", "")))) {
            // 出荷予定数が数値でない時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkCaseNum).replaceAll(",", "")))) {
            // ケース数が数値でない時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkBallNum).replaceAll(",", "")))) {
            // ボール数が数値でない時
            cnt = i + 1;
          } else if (isNaN(Number(commonUtil.zen2han(checkBaraNum).replaceAll(",", "")))) {
            // バラ数が数値でない時
            cnt = i + 1;
          }
        }
      }
      // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
      if (cnt == 0) {
        this.delFlg = true;
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_001_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      } else {
        // 明細部、入力エラー時（カウント1）
        if (this.$refs.editedList.validate()) {
          // 処理なし
        } else {
          for (var j = 0; j < this.inputList.length; j++) {
            let checkPosition = String(j);
            if (!this.inputList[j].productCd) {
              // 関係ないレコードの入力チェックエラーを解除
              eval("this.$refs.productQtyCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.baraSuCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.caseSuCheck" + checkPosition + ".resetValidation()");
              eval("this.$refs.ballSuCheck" + checkPosition + ".resetValidation()");
            } else {
              // 処理なし
            }
          }
        }
      }
    },

    /**
     * 出荷予定登録画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 ケース数
     */
    changeCase(val, index) {
      this.inputList[index].caseSu = commonFunction.getDelimiterFew(val.caseSu, 9, 0);
    },
    clickPop(val) {
      event.target.value = commonFunction.getCommaRemoval(val);
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 ボール数
     */
    changeBall(val, index) {
      this.inputList[index].ballSu = commonFunction.getDelimiterFew(val.ballSu, 9, 0);
    },

    /**
     * 出荷予定登録画面：入力カンマ処理 バラ数
     */
    changeBara(val, index) {
      this.inputList[index].baraSu = commonFunction.getDelimiterFew(val.baraSu, 9, 0);
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      // 選択した明細を取得
      let inputInfo = this.inputList[this.editedIndex];
      // 倉庫名
      inputInfo.warehouse = data.warehouse;
      // 倉庫SID
      inputInfo.warehouseSid = data.warehouseSid;
      // ロケーション名
      inputInfo.location = data.location;
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケーションSID
      inputInfo.locationSid = data.locationSid;

      // 画面を閉じる。
      this.dialogLocation = false;
    },

    /**
     * 付帯情報POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * 出荷予定登録画面：マスタから取得する配送先情報
     */
    getAccMst() {
      this.accKeys.push({ text: "材料", value: "material", required: "0" });
      this.accKeys.push({ text: "備考", value: "bigo", required: "0" });
    },

    /**
     * 出荷予定登録画面：商品コード変更時、行データ削除
     */
    changeProductNm(value, item, index) {
      // 既存品番/品名を選択時()
      let val = this.inputList[index].productList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.productNm = val.name;
        item.productHint = val.text;
        item.search = val.text;
      }
      item.productList = item.productList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.productNm = "";
        item.productHint = "";
        item.serial = "";
        item.serialFlg = "0";
        item.lot = "";
        item.lotFlg = "0";
        item.dueDate = "";
        item.dueDateFlg = "0";
        item.lotSubkey1 = "";
        item.lotSubkey1Flg = "0";
        item.lotSubkey2 = "";
        item.lotSubkey2Flg = "0";
        item.isNew = false;
        item.productQty = null;
        item.caseSu = null;
        item.ballSu = null;
        item.baraSu = null;
        item.locationSid = null;
        item.location = null;
        item.warehouseSid = null;
        item.warehouse = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // ロケーション情報を設定する
      let locationSid = this.inputList[this.editedIndex].locationSid;

      // ロケーションを取得する
      if (locationSid == null || locationSid == "") {
        // 明細にロケーションが設定されていない場合は処理なし
      } else {
        // 明細にロケーションが設定されている場合は、ロケーションNo等を取得し、画面表示させる。
        let resultLocation = this.getLocation(locationSid);
        Promise.all([resultLocation]).then((result) => {
          // ロケーションマスタから取得して値を画面表示用に編集したものを設定する。
          this.location = result[0][0].locationNo + "（" + result[0][0].officeNameShort + "）";
        });
      }

      // マスタを取得する。
      this.getMaster(item);
      // ダイアログを開く
      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報POPUP：登録処理
     */
    save() {
      // ツールチップの値
      var accInfos = this.setItemToList();
      // 付帯情報の名前のリスト
      var accInfoItem = this.setItemTo();
      // 付帯情報の値のリスト
      var accValue = this.setItemCon();

      // 付帯情報リストをクリアする
      this.accList.splice(0);

      // 付帯情報の名前と値のリストを作成する
      let ancillaryInfoList = [];

      // 付帯情報分リストを回す
      for (var i = 0; i < accInfoItem.length; i++) {
        // 付帯情報保持データ
        let ancillaryInfo = { accSelected: "", accSelectedNm: "" };

        // 名前
        ancillaryInfo.accSelected = accInfoItem[i];
        ancillaryInfo.accSelectedNm = accInfoItem[i];

        // 値
        ancillaryInfo.accValue = accValue[i];

        // 付帯情報の名前と値のリストに追加
        ancillaryInfoList.push(ancillaryInfo);
      }

      // 画面表示用付属情報（ツールチップ）
      this.inputList[this.editedIndex].accInfos = accInfos;

      // 付帯情報を設定
      this.inputList[this.editedIndex].accInfoList = ancillaryInfoList;

      // 値渡し用ロケリスト
      this.inputList[this.editedIndex].location = this.location;

      // 子画面ロケーションクリア
      this.location = "";
      this.close();
    },

    /**
     * 出荷予定登録画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 出荷予定登録画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },
    /**
     * 基準日を－１日します。
     */
    prevDate() {
      // 今日以前の日付を選択できないように制御
      // let today = dateTimeHelper.convertJST();
      // if (this.arrivalScheduleDateCal == dateTimeHelper.dateCalc(today)) {
      //   return;
      // }
      let date = new Date(this.shippingScheduleDateCal);
      this.shippingScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 基準日を＋１日します。
     */
    nextDate() {
      let date = new Date(this.shippingScheduleDateCal);
      this.shippingScheduleDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateCal = null;
      }
    },

    /**
     * 取引先一覧と出荷先一覧を取得します。
     */
    getCustomInfo(jstInScheduleDate) {
      // 取引先一覧を取得します。
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );

      // 非同期処理を開始します。
      Promise.all([clientList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の取引先一覧を設定します。
          this.supplierList = result[0];

          // 取引先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.supplierList.length; i++) {
            // 選択された取引先と一致する場合の処理です。
            if (this.searchSuppliersSelected == this.supplierList[i].value) {
              // 選択された取引先に取引先一覧の要素を設定します。
              this.suppliersSelected = this.supplierList[i].text;
            }
          }

          // 出荷先一覧を取得します。
          this.getSuppliersSelected(this.suppliersSelected);
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 出荷先一覧を取得します。
     */
    getSuppliersSelected() {
      // 選択された取引先のSIDを取得します。
      let officeCd = this.supplierList.find(
        (element) => element.value === this.searchSuppliersSelected
      ).clientSid;

      // 出荷先一覧を取得します。
      const toList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.FROMTO,
        dateTimeHelper.convertUTC(this.arrivalScheduleDate),
        undefined,
        officeCd
      );

      // 非同期処理を開始します。
      Promise.all([toList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の出荷先一覧を設定します。
          this.shpList = result[0];

          // 出荷先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.shpList.length; i++) {
            // 出荷先SIDが存在する場合の処理です。
            if (this.toSidDataList) {
              // 出荷先SIDが一致する場合の処理です。
              if (this.toSidDataList == this.shpList[i].value) {
                // 出荷先に出荷先一覧の要素を設定します。
                this.shippingSelected = this.shpList[i].text;
              }
              // 出荷先SIDが存在せず選択された出荷ヘッダーが存在する場合の処理です。
            } else if (this.shippingSelectedHeader) {
              // 選択された出荷ヘッダーが一致する場合の処理です。
              if (this.shippingSelectedHeader == this.shpList[i].value) {
                // 選択された出荷ヘッダーに出荷先一覧の要素を設定します。
                this.shippingSelected = this.shpList[i].text;
              }
            }
          }
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.infoDialog.isOpen = true;

          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * コードマスタを取得します。
     */
    getMstCode() {
      // 有償無償区分一覧を取得します。
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      // 責任区分一覧を取得します。
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);

      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_FREEREASON_DIV);

      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_QUALITY_DIV);

      //個人コード判別用情報
      const individualCodeList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INDIVIDUAL_DIV);

      // 非同期処理を開始します。
      Promise.all([freeCostList, blameList, reasonList, qualityList, individualCodeList])
        // 成功時の処理です。
        .then((result) => {
          // 有償無償区分一覧を設定します。
          this.freeCostDivList = result[0];

          // 責任区分一覧の最初のなしを削除します。
          result[1].splice(0, 1);

          // 責任区分一覧を設定します。
          this.blameDivList = result[1];

          //理由コード一覧を設定します。
          this.freeReasonList = result[2];

          this.shippingStatusList = result[3];

          this.individualList = result[4];
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 出荷予定登録画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },

    /**
     * 出荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      // 初期処理の場合、inputListの内容をキャッシュ用リストに移行する。
      if (this.initFlg) {
        for (var n = 0; n < this.inputList.length; n++) {
          this.changeList[n] = this.inputList[n];
        }
        this.initFlg = false;
      }

      this.editedIndex = this.inputList.indexOf(item);
      var changeIndex = this.inputList[this.editedIndex].No - 1;

      // 削除したリストを退避する。
      if (item.isAdd !== true) {
        this.deleteList.push(this.inputList[this.editedIndex]);
      }
      this.changeList.splice(changeIndex, 1);
      var i = 1;
      // 番号付け

      this.changeList.forEach((data) => {
        data.No = i;
        i += 1;
      }); // inputList に修正した内容を移行

      this.useCache();

      if (this.changeList.length == 0) {
        this.totalCount = 0;
        this.totalPage = 0;
        this.totalRecord = 0;
      } else {
        // console.debug("行削除");
        var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
        var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

        // 予定数合計から削除したレコードの数を引く
        this.totalCount = Number(totalNum) - Number(proQty);
        // カンマ編集
        if (
          this.totalPage > Math.ceil(this.changeList.length / this.itemsPerPage) &&
          this.page == this.totalPage
        ) {
          this.inputList = [];
        }
        this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
        this.totalPage = Math.ceil(this.changeList.length / this.itemsPerPage);
        this.totalRecord = this.totalRecord - 1;
      }
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;

      this.$refs["productQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["caseSuCheck" + this.editedIndex].resetValidation();
      this.$refs["ballSuCheck" + this.editedIndex].resetValidation();
      this.$refs["baraSuCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 出荷予定登録画面：付帯情報遷移処理
     */
    openAcc(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);
    },

    /**
     * ロケーション予約POPUP閉じる
     */
    closePopup() {
      this.dialogLocation = false;
    },

    /**
     * 出荷予定登録画面：商品コードリストボックスセット処理
     */
    checkProductCd(value, index) {
      //S/N活性・非活性判定
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (this.inputList[index].productList[i].value == this.inputList[index].productCd) {
          this.inputList[index].serialFlg = this.inputList[index].productList[i].serialFlg;
          this.inputList[index].lotFlg = this.inputList[index].productList[i].lotFlg;
          this.inputList[index].dueDateFlg = this.inputList[index].productList[i].dueDateFlg;
          this.inputList[index].lotSubkey1Flg = this.inputList[index].productList[i].lotSubkey1Flg;
          this.inputList[index].lotSubkey2Flg = this.inputList[index].productList[i].lotSubkey2Flg;
        }
        if (this.inputList[index].serialFlg == "0") {
          this.inputList[index].serial = "";
        }
        if (this.inputList[index].lotFlg == "0") {
          this.inputList[index].lot = "";
        }
        if (this.inputList[index].dueDateFlg == "0") {
          this.inputList[index].dueDate = "";
        }
        if (this.inputList[index].lotSubkey1Flg == "0") {
          this.inputList[index].lotSubkey1 = "";
        }
        if (this.inputList[index].lotSubkey2Flg == "0") {
          this.inputList[index].lotSubkey2 = "";
        }
      }
      return this.inputList[index].productHint;
    },

    /**
     * 出荷予定登録画面：ケース数計算処理
     */
    setHintCase(value, index) {
      return this.inputList[index].caseSuHint;
    },

    /**
     * 出荷予定登録画面：ボール数計算処理
     */
    setHintBall(value, index) {
      return this.inputList[index].ballSuHint;
    },

    /**
     * 出荷予定登録画面：バラ数計算処理
     */
    setHintBara(value, index) {
      return this.inputList[index].baraSuHint;
    },

    /**
     * 出荷予定修正・削除画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.searchSuppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;
      // 追加ダイアログを開く
      this.isOpenShippingAcheiveDialog = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 出荷予定登録画面：出荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");
      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }
      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      // 選択した商品コードがproductListに何番目に入っているかを調べる。
      let selectedProductQuantity = 0;
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (inputListProductCd == this.inputList[index].productList[i].value) {
          selectedProductQuantity = this.inputList[index].productList[i].quantity;
        }
      }
      // 各数量項目の計算処理
      if (!isNaN(Number(proQty))) {
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        if (0 < selectedProductQuantity) {
          // 入荷予定数が入力されている場合
          this.inputList[index].caseSu = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].caseSuHint = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].baraSu = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].baraSuHint = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].ballSu = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          this.inputList[index].ballSuHint = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          // カンマ編集して数量にセット
          this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        } else {
          this.inputList[index].caseSu = "0";
          this.inputList[index].ballSu = "0";
          this.inputList[index].baraSu = "0";
        }
      }

      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        var shipQuantity = 0;
        var productQty = commonUtil.zen2han(this.inputList[index].productQty).replaceAll(",", "");
        var quantity = commonUtil.zen2han(this.quantityList[index]).replaceAll(",", "");

        shipQuantity = Number(totalNum) + Number(productQty) - Number(quantity);

        this.quantityList[index] = this.inputList[index].productQty;

        this.totalCount = commonUtil.formatToCurrency(shipQuantity);
      }

      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        // ロケ登録なし
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateuleDate);
      } else {
        // ロケ登録あり
        this.locInfo.location = this.inputList[this.editedIndex].location;
        this.locInfo.productCd = this.inputList[this.editedIndex].productCd;
        this.locInfo.init = false;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateuleDate);
      }
      this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDateuleDate);
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 付帯情報POPUP：行追加処理
     */
    addAccItem() {
      this.accList.push({ accSelected: "", accValue: "" });
    },

    /**
     * 出荷予定登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * アラートダイアログ「OK」ボタン押下
     */
    okClicked() {
      this.fixInsertDialogMsg = false;
    },

    /**
     * 出荷予定登録画面：登録ボタン押下
     */
    shpScheAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      this.useCache();
      this.setDispWarehouseSidForCache();
      for (let i = 0; i < this.changeList.length; i++) {
        this.changeList[i].requiredItemCheckMsg = i18n.tc("");
        this.changeList[i].warehouseCheckMsg = i18n.tc("");
        this.changeList[i].locationCheckMsg = i18n.tc("");
      }
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 0;

      let cnt = 0;
      let countCheckFlg = true;
      this.alertMessage = "";
      // 伝票部、入力チェック実施
      if (this.$refs.form.validate()) {
        if (this.$refs.editedList.validate()) {
          // 明細部未入力チェック準備
          let checkList = []; // 明細部チェックリスト
          for (let i = 0; i < this.changeList.length; i++) {
            if (this.changeList[i].productCd) {
              // 明細部の品番品名が入力されている場合はリストに追加
              checkList.push(this.changeList[i].productCd);
            } else {
              return;
            }
          }
          if (
            // FromToの日付、入力チェック
            this.dateToDelivery != null &&
            new Date(this.shippingScheduleDate).getTime() > new Date(this.dateToDelivery).getTime()
          ) {
            this.alertMessage = i18n.tc("check.chk_inputDeliveryVali");
            return;
          } else {
            // 処理なし
          }

          var errNo = false;
          for (let i = 0; i < this.changeList.length; i++) {
            let count = 0;
            // 品番の入力有無チェック
            if (this.changeList[i].productCd != null && this.changeList[i].productCd != "") {
              count++;
            }
            // 出荷予定数の入力有無チェック
            if (this.changeList[i].productQty != null && this.changeList[i].productQty != "") {
              count++;
            }
            // ケース数の入力有無チェック
            if (this.changeList[i].caseSu != null && this.changeList[i].caseSu != "") {
              count++;
            }
            // ボール数の入力有無チェック
            if (this.changeList[i].ballSu != null && this.changeList[i].ballSu != "") {
              count++;
            }
            // バラ数の入力有無チェック
            if (this.changeList[i].baraSu != null && this.changeList[i].baraSu != "") {
              count++;
            }
            // 倉庫の入力有無チェック
            if (this.changeList[i].warehouse != null && this.changeList[i].warehouse != "") {
              count++;
            }
            // ロケの入力有無チェック
            if (this.changeList[i].location != null && this.changeList[i].location != "") {
              count++;
            }
            if (count > 0) {
              // 必須項目が未入力のチェック
              if (
                this.changeList[i].productCd == null ||
                this.changeList[i].productCd == "" ||
                this.changeList[i].productQty == null ||
                this.changeList[i].productQty == "" ||
                this.changeList[i].caseSu == null ||
                this.changeList[i].caseSu == "" ||
                this.changeList[i].ballSu == null ||
                this.changeList[i].ballSu == "" ||
                this.changeList[i].baraSu == null ||
                this.changeList[i].baraSu == ""
              ) {
                // 未入力の明細Noの取得
                errNo = true;
                // 未入力の必須項目エラーメッセージ
                this.changeList[i].requiredItemCheckMsg = i18n.tc("check.chk_input");
              }
            }
            // 倉庫ロケーションの相関チェック(倉庫、ロケいずれかが入力されていた場合は両方入力されていないとNG)
            // 倉庫
            let warehouse = this.changeList[i].warehouse;
            // ロケ
            let location = this.changeList[i].location;

            // 入力チェック
            if ((warehouse == null || warehouse == "") && (location == null || location == "")) {
              // 倉庫とロケが両方入力されていない場合は処理なし
            } else {
              // 上記以外の場合
              if (warehouse != null && warehouse != "" && location != null && location != "") {
                // 倉庫ロケ両方入力されている場合は処理なし
              } else {
                if (warehouse == null || warehouse == "") {
                  // ロケーションのみ入力されている場合
                  this.changeList[i].warehouseCheckMsg = i18n.tc("check.chk_input");
                  errNo = true;
                } else {
                  // 上記以外の場合（倉庫のみ入力されている場合）
                  this.changeList[i].locationCheckMsg = i18n.tc("check.chk_input");
                  errNo = true;
                }
              }
            }
          }

          if (errNo) {
            return;
          }

          // 明細部の未入力チェック
          if (checkList.length <= 0) {
            // 上記でのチェックリストが0件の場合は、警告メッセージ表示
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
            cnt = i + 1;
            countCheckFlg = true;
          } else {
            // 処理なし
          }

          // 明細部、入力チェック実施
          for (var i = 0; i < this.changeList.length; i++) {
            if (this.changeList[i].productCd) {
              var checkQtyNum = Number(
                commonUtil.zen2han(this.changeList[i].productQty).replaceAll(",", "")
              );
              var checkCaseNum = Number(
                commonUtil.zen2han(this.changeList[i].caseSu).replaceAll(",", "")
              );
              var checkBaraNum = Number(
                commonUtil.zen2han(this.changeList[i].baraSu).replaceAll(",", "")
              );
              var checkBallNum = Number(
                commonUtil.zen2han(this.changeList[i].ballSu).replaceAll(",", "")
              );

              if (checkQtyNum == 0) {
                // 出荷予定数が0の場合、警告メッセージ表示
                this.errorFlg = false;
                this.updateDialogMessage = true;
                this.backMessage = messsageUtil.getMessage("P-SHP-003_001_E");
                cnt = i + 1;
                countCheckFlg = true;
              } else {
                // 処理なし
              }

              if (checkQtyNum == "") {
                // 出荷予定数が未入力時
                cnt = i + 1;
              } else {
                // 処理なし
              }

              // 数値チェック
              if (
                isNaN(checkQtyNum) ||
                isNaN(checkBallNum) ||
                isNaN(checkCaseNum) ||
                isNaN(checkBaraNum)
              ) {
                // 出荷予定数、ボール数、ケース数、バラ数が数値でない時
                cnt = i + 1;
              } else {
                // 処理なし
              }
              // 整数チェック
              if (
                Number.isInteger(checkQtyNum) &&
                Number.isInteger(checkBallNum) &&
                Number.isInteger(checkCaseNum) &&
                Number.isInteger(checkBaraNum)
              ) {
                // 処理なし
              } else {
                // 出荷予定数、ボール数、ケース数、バラ数が整数でない時
                cnt = i + 1;
              }

              // 範囲チェック（0未満）
              if (0 < checkQtyNum && 0 < checkBallNum && 0 < checkCaseNum && 0 <= checkBaraNum) {
                // 処理なし
              } else {
                // 入荷予定数、ボール数、ケース数、バラ数が0未満の場合
                cnt = i + 1;
              }
            }
          }
          // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
          if (cnt == 0 && countCheckFlg) {
            if (this.isPaid == "02") {
              this.ConfirmDialog.screenFlag = false;
              this.ConfirmDialog.changeFlag = true;
              this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
              this.ConfirmDialog.redMessage = "無償";
              this.ConfirmDialog.title = "警告";
              this.ConfirmDialog.isOpen = true;
              this.addFlg = true;
            } else {
              this.addFlg = true;
              this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
              this.ConfirmDialog.isOpen = true;
              this.ConfirmDialog.screenFlag = true;
              this.ConfirmDialog.changeFlag = false;
            }
          } else {
            // 明細部、入力エラー時（カウント1）
            if (this.$refs.editedList.validate()) {
              // 処理なし
            } else {
              for (var j = 0; j < this.inputList.length; j++) {
                let checkPosition = String(j);
                if (!this.inputList[j].productCd) {
                  // 関係ないレコードの入力チェックエラーを解除
                  eval("this.$refs.productQtyCheck" + checkPosition + ".resetValidation()");
                  eval("this.$refs.baraSuCheck" + checkPosition + ".resetValidation()");
                  eval("this.$refs.caseSuCheck" + checkPosition + ".resetValidation()");
                  eval("this.$refs.ballSuCheck" + checkPosition + ".resetValidation()");
                } else {
                  // 処理なし
                }
              }
            }
          }
        } else {
          // 伝票部、入力チェックエラーの場合、処理なし
        }
      }
    },

    /**
     * 出荷予定登録画面： 付属情報_商品付属マスタAPI(GET)
     */

    getMaster(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 商品付帯情報
      const itemsIndividual = getParameter.getItemIndividual(
        this.inputList[this.editedIndex].productCd
      );

      Promise.all([itemsIndividual])
        .then((result) => {
          // 画面の初期値を設定します。
          this.accKeys = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.simpleDialog = true;
          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 出荷予定登録画面： 品番/品名_業務利用商品API(GET)
     */
    getCdName() {
      const itemsBiz = getParameter.getItemsBiz(
        this.searchSuppliersSelected,
        this.shippingScheduleDateJ
      );

      Promise.all([itemsBiz])
        .then((result) => {
          // 画面の初期値を設定します。
          this.productList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.simpleDialog = true;
          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 出荷予定登録画面：出荷予定API（POST）
     */
    addShpSch() {
      // メッセージ初期化
      this.alertMessage = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_004;
      // 出荷伝票SID
      body.reqIdv.outListSid = this.shpInputDataList[0].shipSid;
      // 営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      body.reqIdv.clientSid = this.searchSuppliersSelected;
      // 出荷伝票No
      body.reqIdv.outListNo = this.txt_slipNo;
      // 出荷予定日
      body.reqIdv.outScheduleDate = this.shippingScheduleDateJ;
      body.reqIdv.outScheduleDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
      // 修正区分(02：修正)
      body.reqIdv.modifyDiv = "02";
      // 有償無償区分
      body.reqIdv.freeCostDiv = this.isPaid;
      // 処理区分
      body.reqIdv.processDiv = this.shpInputDataList[0].processingdivision;
      // 理由CD
      body.reqIdv.reasonCd = this.freeReasonSelected;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // FromSID
      body.reqIdv.fromSid = sessionStorage.getItem("sales_office_sid");
      // ToSid
      body.reqIdv.toSid = this.isDisableShippingDropdown
        ? this.shpInputDataList[0].toSid
        : this.shippingSelected;
      // お届け予定日
      body.reqIdv.deliveryDate = this.dateToDelivery;

      body.reqIdv.privateInfSid = this.privateInfSid;
      // 個人宛の場合
      if (!this.individualButton) {
        //
        body.reqIdv.privateIdentityFlg = "1";
        //発注者情報
        body.reqIdv.shipFromPostCd = this.shipFromPostCd;
        body.reqIdv.shipFromName = this.shipFromName;
        body.reqIdv.shipFromPrefectures = "";
        body.reqIdv.shipFromMunicipalities = "";
        body.reqIdv.shipFromCharacterName = "";
        body.reqIdv.shipToAddressDetail = this.shipToCharacterName;
        body.reqIdv.shipFromTelephoneNumber = this.shipFromTelephoneNumber;
        //発注者情報
        body.reqIdv.deliveryTime = this.deliveryTime;
        body.reqIdv.purchasePrice = commonFunction.getCommaRemoval(this.purchasePrice);
        //届け先情報
        body.reqIdv.shipToName = this.shipToName;
        body.reqIdv.relatedPartyNameShort = this.shipToName.substr(0, 20);
        body.reqIdv.shipToPostCd = this.shipToPostCd;
        body.reqIdv.shipToPrefectures = "";
        body.reqIdv.shipToMunicipalities = "";
        body.reqIdv.shipToCharacterName = "";
        body.reqIdv.shipFromAddressDetail = this.shipFromCharacterName;
        body.reqIdv.shipToTelephoneNumber = this.shipToTelephoneNumber;
      }
      //企業宛の場合
      else {
        body.reqIdv.privateIdentityFlg = "0";
      }
      body.reqIdv.businessSid = "";
      // 輸送会社区分
      body.reqIdv.carrierCompCd = this.truckKbn;
      body.reqIdv.deliveryTraderSid = "";
      if (this.truckKbn == appConfig.CARRIERCOMPDIV.DELIVERY) {
        // 輸送会社区分が04であれば、路線業者SIDを登録
        // 路線業者SID
        body.reqIdv.deliveryTraderSid = this.truck;
      }

      // 送り状発行日
      body.reqIdv.invoicePrintDate = this.shpInputDataList[0].invoicePrintDate;
      // 荷札発行日
      body.reqIdv.taglabelPrintDate = this.shpInputDataList[0].taglabelPrintDate;
      // 備考
      body.reqIdv.remarks = this.remarks;
      if (this.addFlg) {
        // 削除フラグ(修正登録)
        body.reqIdv.deleteFlg = "0";
      } else if (this.delFlg) {
        // 削除フラグ（伝票削除）
        body.reqIdv.deleteFlg = "1";
      }
      // 更新日時(出荷予定でGETした更新日時)
      body.reqIdv.updateDatetime = this.shpInputDataList[0].updateDatetime;
      body.reqIdv.additionalInfoUpdateDatetime =
        this.shpInputDataList[0].additionalInfoUpdateDatetime;

      // 出荷予定明細List
      var bodyList = [];

      // 修正可能なレコードのみPOSTする
      for (var i = 0; i < this.changeList.length; i++) {
        if (!(this.changeList[i].completeFlg > "0" && this.changeList[i].isAllOrder > 0)) {
          if (this.changeList[i].productCd != "" && this.changeList[i].productCd != null) {
            var inputMap = {};
            // 出荷伝票明細No
            inputMap.outListSubNo = this.changeList[i].No;
            // 出荷伝票明細Sid
            inputMap.outListSubSid = this.changeList[i].outListSubSid;
            // 商品標準SID
            inputMap.itemStandardSid = this.changeList[i].productCd;
            // 品番
            for (var m = 0; m < this.changeList[i].productList.length; m++) {
              if (this.changeList[i].productList[m].value == this.changeList[i].productCd) {
                inputMap.itemCd = this.changeList[i].productList[m].code;
              }
            }
            if (this.addFlg) {
              // 削除フラグ(修正登録)
              inputMap.deleteFlg = "0";
            } else if (this.delFlg) {
              // 削除フラグ（伝票削除）
              inputMap.deleteFlg = "1";
            }
            // ロット
            inputMap.lotNo = commonUtil.createLotNo(
              this.changeList[i].lot,
              this.changeList[i].serial,
              this.changeList[i].dueDate,
              this.changeList[i].lotSubkey1,
              this.changeList[i].lotSubkey2
            );
            // 出荷予定数
            inputMap.shipScheduleQuantity = this.changeList[i].productQty.replaceAll(",", "");
            // 出荷荷姿数
            inputMap.shipPackingQuantity = this.changeList[i].caseSu.replaceAll(",", "");
            // 出荷単位数
            inputMap.shipUnitQuantity = this.changeList[i].ballSu.replaceAll(",", "");
            // 端数
            inputMap.fracQuantity = this.changeList[i].baraSu.replaceAll(",", "");
            // 品質区分
            inputMap.qualityDiv = this.shippingStatusSelected;
            inputMap.deliveryDate = this.dateToDelivery;
            // 更新日時(出荷詳細でGETしたの更新日時)
            inputMap.updateDatetime = this.changeList[i].updateDatetime;

            // 情報分類明細List
            this.infoCategoryDtl = [];
            //付帯情報
            if (this.changeList[i].accInfoList) {
              for (var j = 0; j < this.changeList[i].accInfoList.length; j++) {
                var accInfoMap = {};
                accInfoMap.subInformation = this.changeList[i].accInfoList[j].accSelected;
                accInfoMap.subInformationComment = this.changeList[i].accInfoList[j].accValue;
                this.infoCategoryDtl.push(accInfoMap);
              }
            }

            // ロケーション
            if (this.changeList[i].locationSid) {
              inputMap.locationSid = this.changeList[i].locationSid;

              for (let m = 0; m < this.changeList[i].locationList.length; m++) {
                if (this.changeList[i].locationList[m].value == this.changeList[i].locationSid) {
                  inputMap.locationNo = this.changeList[i].locationList[m].name;
                }
              }
            }

            // ロケ予約List
            this.locationReserve = [];
            if (this.changeList[i].location) {
              var warehouseMap = {};
              warehouseMap.locationSid = this.changeList[i].locationSid;
              this.locationReserve.push(warehouseMap);
            }
            // // ロケ予約List(詳細登録POPUP用)
            // this.locationReserve = [];
            // if (this.inputList[i].location) {
            //   var warehouseMap = {};
            //   warehouseMap.locationSid = this.inputList[i].locationSid;
            //   this.locationReserve.push(warehouseMap);
            // }
            inputMap.locationReserve = this.locationReserve;
            inputMap.infoCategoryDtl = this.infoCategoryDtl;

            bodyList.push(inputMap);
          }
        }
      }
      // 削除分の明細データをbodyListに追加する。
      // 画面上に表示されている明細
      for (var x = 0; x < this.deleteList.length; x++) {
        if (this.deleteList[x].productCd != "" && this.deleteList[x].productCd != null) {
          var inputMapdel = {};
          //入荷伝票明細No
          inputMapdel.outListSubNo = this.deleteList[x].No;
          // 入荷伝票SID
          inputMapdel.outListSubSid = this.deleteList[x].outListSubSid;
          //商品標準Sid
          inputMapdel.itemStandardSid = this.deleteList[x].productCd;

          // 品番
          for (var y = 0; y < this.deleteList[x].productList.length; y++) {
            if (this.deleteList[x].productList[y].value == this.deleteList[x].productCd) {
              //品番
              inputMapdel.itemCd = this.deleteList[x].productList[y].code;
            }
          }
          // ロット
          inputMapdel.lotNo = commonUtil.createLotNo(
            this.deleteList[x].lot,
            this.deleteList[x].serial,
            this.deleteList[x].dueDate,
            this.deleteList[x].lotSubkey1,
            this.deleteList[x].lotSubkey2
          );
          // 入荷予定数
          inputMapdel.shipScheduleQuantity = this.deleteList[x].productQty.replaceAll(",", "");
          // ケース
          inputMapdel.shipPackingQuantity = this.deleteList[x].caseSu.replaceAll(",", "");
          // ボール
          inputMapdel.shipUnitQuantity = this.deleteList[x].ballSu.replaceAll(",", "");
          // バラ
          inputMapdel.fracQuantity = this.deleteList[x].baraSu.replaceAll(",", "");
          // 品質区分
          inputMapdel.qualityDiv = this.deleteList[x].qualityDiv;
          // 削除フラグ
          inputMapdel.deleteFlg = "1";
          // 更新日時(入荷詳細でGETしたの更新日時)
          inputMapdel.updateDatetime = this.deleteList[x].updateDatetime;

          // 情報分類明細List
          this.infoCategoryDtl = [];
          if (this.deleteList[x].accInfoList) {
            for (var z = 0; z < this.deleteList[x].accInfoList.length; z++) {
              var accInfoMapdel = {};
              accInfoMapdel.subInformation = this.deleteList[x].accInfoList[z].accSelected; //付帯情報
              accInfoMapdel.subInformationComment = this.deleteList[x].accInfoList[z].accValue; //付帯内容
              this.infoCategoryDtl.push(accInfoMapdel);
            }
          }

          // ロケ予約List
          this.locationReserve = [];
          if (this.deleteList[x].location) {
            var warehouseMapdel = {};
            warehouseMapdel.locationSid = this.deleteList[x].locationSid;
            this.locationReserve.push(warehouseMapdel);
          }
          //ロケ予約
          inputMapdel.locationReserve = this.locationReserve;
          //付帯情報
          inputMapdel.infoCategoryDtl = this.infoCategoryDtl;

          bodyList.push(inputMapdel);
        }
      }
      body.reqIdv.outScheduleDtl = bodyList;
      // console.debug("addShpSch() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // ここがGETとの違い。APIの接続方法としては同じ
          .securePost(appConfig.API_URL.BIZ_OUTSCHEDULES, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addShpSch() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              this.infoDialog.homePageFlag = true;
              this.infoDialog.shpSchListFlg = true;
              this.infoDialog.shpSchAddFlg = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;

              // ボタン識別フラグ
              this.addFlg = false;
              this.delFlg = false;

              resolve(response);

              // エラー時
            } else {
              reject(new Error(jsonData.resCom.resComMessage));
            }
          })
          .catch((ex) => {
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).catch((ex) => {
        this.infoDialog.message = ex.message;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.simpleDialog = true;
        this.infoDialog.firstPageFlag = true;
        this.infoDialog.homePageFlag = false;
        this.infoDialog.shpSchListFlg = false;
        this.infoDialog.shpSchAddFlg = false;
      });
    },
    /**
     * 倉庫リストボックスセット処理
     */
    checkWarehouse(value) {
      return commonFunction.getListSetName(this.warehouseList, value.warehouse);
    },

    /**
     * ロケリストボックスセット処理
     */
    checkLocation(item) {
      // return this.inputList[index].locationHint;

      return item.locationHint;
    },

    /**
     * 倉庫マスタを取得します。
     */
    getWarehouse() {
      // GetAPIリクエスト用のConfigを生成します。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 営業所SIDを設定します。
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");

      // マスタ取得有無を設定します。
      config.params.isMst = "0";

      // 検索区分を設定します。
      config.params.searchCategory = "0";

      // 非同期処理を開始します。
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 業務利用倉庫マスタを取得します。
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          // 成功時の処理です。
          .then((response) => {
            // レスポンスをログ出力します。
            // console.debug("getWarehouse() Response", response);

            // レスポンスからJSONオブジェクトを取得します。
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 処理結果コードが"000"（Success・Retry）の場合の処理です。
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // リストを生成します。
              const list = [];

              // JSONオブジェクトに対する繰り返し処理です。
              jsonData.resIdv.warehouses.forEach((row) => {
                // リストにハッシュを追加します。
                list.push({
                  // 倉庫名称
                  text: row.warehouseLanguage[0].warehouseName,

                  // 倉庫SID
                  value: row.warehouseLanguage[0].warehouseSid,

                  // 倉庫名称
                  name: row.warehouseLanguage[0].warehouseName,

                  // 倉庫名称略称
                  nameShort: row.warehouseLanguage[0].warehouseNameShort,
                });
              });

              // リストを倉庫一覧に設定します。
              this.warehouseList = list;

              // タスクを終了します（成功）。
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;

              this.infoDialog.title = appConfig.DIALOG.title;

              this.simpleDialog = true;

              this.infoDialog.firstPageFlag = true;

              // タスクを終了します（失敗）。
              reject();
            }
          })
          // 失敗時の処理です。
          .catch((ex) => {
            this.infoDialog.message = ex;

            this.infoDialog.title = appConfig.DIALOG.title;

            this.simpleDialog = true;

            this.infoDialog.firstPageFlag = true;

            // タスクを終了します（失敗）。
            reject();
          });
      });
    },

    /**
     * ロケリストボックス変更時メソッド
     */
    changeLocation(value, item, index) {
      // 既存ロケを選択時
      let val = this.inputList[index].locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.locationHint = val.text;
        item.location = val.value;
        // item.locaSearch = val.text;
      }
      item.locationList = item.locationList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }
    },

    /**
     * 出荷予定修正削除画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 出荷予定修正削除画面：お届け先日付を－１日
     */
    prevDeliveryDate() {
      this.dateToDeliveryCal = commonFunction.addDate(this.dateToDeliveryCal, "");
    },

    /**
     * 出荷予定修正削除画面：お届け先日付を＋１日
     */
    nextDeliveryDate() {
      this.dateToDeliveryCal = commonFunction.addDate(this.dateToDeliveryCal, "add");
    },
    /**
     * 出荷予定修正削除画面：お届け先日付入力処理
     */
    changeDeliveryDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.dateToDeliveryCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.dateToDeliveryCal = null;
      }
    },

    /**
     * 出荷予定登録画面：個人宛ボタン押下時
     */
    btnIndividual() {
      this.modalset();
      this.dialogDeliveryAdress = true;
    },

    /**
     * 個人宛情報：閉じるボタン押下時
     */
    popDeliveryAdressClose() {
      this.dialogDeliveryAdress = false;
    },

    /**
     * 個人宛情報-保管の値をmodalへセット
     */
    modalset() {
      //保管の値をmodalへセット---------------------------------------------//
      //届け先名
      this.destination = this.shipToName;
      //届け先-郵便番号
      this.destinationPostCode = this.shipToPostCd;
      //届け先-住所
      this.destinationAddress = this.shipToCharacterName;
      //届け先-電話番号
      this.destinationphoneCode = this.shipToTelephoneNumber;
      //納品時間
      this.deliveryDate = this.deliveryTime;
      //購入金額
      this.purchaseAmount = this.purchasePrice;
      //発注者名
      this.ordererName = this.shipFromName;
      //発注者-郵便番号
      this.ordererPostCd = this.shipFromPostCd;
      //発注者-住所
      this.ordererAddress = this.shipFromCharacterName;
      //発注者-電話番号
      this.orderphoneNumber = this.shipFromTelephoneNumber;
      //modalの値を保管へセット---------------------------------------------//
    },

    /**
     * 「届け先と同様ボタン押下時
     */
    destinationSet() {
      //届け先名を発注者名へセット
      this.ordererName = this.destination;
      //届け先-郵便番号を発注者-郵便番号へセット
      this.ordererPostCd = this.destinationPostCode;
      //届け先-住所を発注者-住所へセット
      this.ordererAddress = this.destinationAddress;
      //届け先-電話番号を発注者-電話番号へセット
      this.orderphoneNumber = this.destinationphoneCode;
    },
    /**
     * 個人宛情報登録
     */
    popDeliveryAdressSave() {
      if (this.$refs.destinationList.validate()) {
        //ヘッダの表示項目にセット(届け先名)
        this.destinationDisplay = this.destination;
        //ヘッダの表示項目にセット(届け先名住所)
        this.destinationAddressDisplay = this.destinationAddress;
        //modalの値を保管へセット---------------------------------------------//
        //届け先名
        this.shipToName = this.destination;
        //届け先-郵便番号
        this.shipToPostCd = this.destinationPostCode;
        //届け先-住所
        this.shipToCharacterName = this.destinationAddress;
        //届け先-電話番号
        this.shipToTelephoneNumber = this.destinationphoneCode;
        //納品時間
        this.deliveryTime = this.deliveryDate;
        //購入金額
        this.purchasePrice = this.purchaseAmount;
        //発注者名
        this.shipFromName = this.ordererName;
        //発注者-郵便番号
        this.shipFromPostCd = this.ordererPostCd;
        //発注者-住所
        this.shipFromCharacterName = this.ordererAddress;
        //発注者-電話番号
        this.shipFromTelephoneNumber = this.orderphoneNumber;
        //modalの値を保管へセット---------------------------------------------//
        //個人宛情報非表示
        this.dialogDeliveryAdress = false;
      }
    },

    /**
     * クリアボタン押下時
     */
    popDeliveryAdressCear() {
      //届け先名(modal)
      this.destination = "";
      //届け先-郵便番号(modal)
      this.destinationPostCode = "";
      //届け先-住所(modal)
      this.destinationAddress = "";
      //届け先-電話番号(modal)
      this.destinationphoneCode = "";
      //納品時間(modal)
      this.deliveryDate = "";
      //購入金額(modal)
      this.purchaseAmount = "";
      //発注者名(modal)
      this.ordererName = "";
      //発注者-郵便番号(modal)
      this.ordererPostCd = "";
      //発注者-住所(modal)
      this.ordererAddress = "";
      //発注者-電話番号(modal)
      this.orderphoneNumber = "";
    },
    /**
     * 引当済データが存在しているか判定する
     */
    judgeIsWorkingAtLeastOne() {
      return (
        0 <
        this.inputList.filter((element) => {
          return (
            element.completeFlg == "1" ||
            element.isAllOrder > "0" ||
            (element.isAllOrder > "1" && element.isOrder != "0") ||
            (element.isAllOrder > "1" && element.isOrder == "0")
          );
        }).length
      );
    },
    /**
     * 出荷予定登録画面：出荷先コードリストボックス取得処理
     */
    setSrhShipping() {
      return commonFunction.getListSetName(this.shippingList, this.shippingSelected);
    },
    /** 輸送会社取得処理 */
    changeCarrier() {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      if (this.$route.params.screenName == appConfig.SCREEN_ID.P_TMP_903) {
        // 取引先SID
        config.params.clientSid = this.searchParam.searchSuppliersSelected;
      } else {
        // 取引先SID
        config.params.clientSid = this.$route.params.suppliersSelected;
      }

      //出荷先SID
      config.params.toSid = this.shippingSelected;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_DELIVERY_BIZUSE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              jsonData.resIdv.deliveryTrader.forEach((row) => {
                if (row.warehouseOwnerDiv != "04") {
                  //路線会社以外の場合
                  this.truckNm = row.warehouseOwnerDivName;
                  // API検索用
                  this.truckKbn = row.warehouseOwnerDiv;
                  this.truck = "";
                } else {
                  //路線会社の場合
                  this.truckNm = row.deliveryTraderName;
                  // API検索用
                  this.truckKbn = row.warehouseOwnerDiv;
                  this.truck = row.deliveryTraderSid;
                }
              });
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          });
      });
    },
    /**
     * 出荷先プルダウンチェンジイベント
     */
    changeShipping(value) {
      // 出荷先を初期化
      this.initShipping(value);
      //プルダウンに表示されるを出荷先を選択したもののみに変更
      this.shippingList = this.shippingList.filter((v) => v.value == value);
      //輸送業者表示対応
      if (this.shippingSelected != "" && this.shippingSelected != null) {
        //輸送会社取得
        this.changeCarrier();
      } else {
        // 画面表示用
        this.truckNm = "";
        // API検索用
        this.truckKbn = "";
        this.truck = "";
      }

      //個人宅用処理
      if (this.shippingSelected != null) {
        for (var i = 0; i < this.shippingList.length; i++) {
          for (var b = 0; b < this.individualList.length; b++) {
            if (this.shippingList[i].value == this.shippingSelected) {
              // if (this.shippingList[i].baseCd == this.individualCode) {
              if (this.shippingList[i].baseCd == this.individualList[b].text) {
                //「個人宅」ボタン活性処理
                this.individualButton = false;
                this.dialogDeliveryAdress = true;
                //3行目表示
                this.deliverystyle = "display: block";
                break;
              } else {
                this.initializationdestination();
              }
            }
          }
        }
      } else {
        this.initializationdestination();
      }
    },
    /**
     * 出荷先プルダウン初期化イベント
     */
    initShipping(value) {
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.shippingHint = val.displayText;
        this.getShippingSelected = val.displayText;
      } else if (value == "" || value == null) {
        this.shippingHint = "";
      }
    },
    /**
     * 個人宛情報初期化
     */
    initializationdestination() {
      //「個人宅」ボタン非活性
      this.individualButton = true;
      //3行目非表示
      this.deliverystyle = "display: none";
      this.initialDisplay();
    },
    useCache() {
      //初期ページの場合inputListをキャッシュ用リストに格納
      if (this.initFlg) {
        for (var n = 0; n < this.inputList.length; n++) {
          this.changeList[n] = this.inputList[n];
        }
        this.initFlg = false;
      }
      if (this.changeList.length == 0) {
        this.inputList = [];
      } else {
        // キャッシュ用リストに該当項目があるか検索
        for (var i = 0; i < this.changeList.length / this.itemsPerPage; i++) {
          // 該当項目がある場合
          if (
            this.changeList[i * this.itemsPerPage].No ==
            (this.page - 1) * this.itemsPerPage + 1
          ) {
            this.inputList = [];
            for (var j = 0; j < this.itemsPerPage; j++) {
              if (
                this.changeList[i * this.itemsPerPage + j].No ==
                (this.page - 1) * this.itemsPerPage + 1 + j
              ) {
                this.inputList.push(this.changeList[i * this.itemsPerPage + j]);
                const newItem = this.inputList[this.inputList.length - 1];
                newItem.isWatchAdded = undefined;
                this.quantityList[j] = this.changeList[i * this.itemsPerPage + j].productQty;
              }
              if (this.changeList[i * this.itemsPerPage + j].No == this.changeList.length) {
                break;
              }
            }
            // 倉庫を取得
            if (!this.initFlg) {
              this.setDispWarehouseSid();
            }

            break;
          }
        }
      }
    },
    initialDisplay() {
      //個人宛情報各項目初期化
      //届け先名(表示用)
      this.destinationDisplay = "";
      //届け先名(保管)
      this.shipToName = "";
      //届け先-郵便番号(保管)
      this.shipToPostCd = "";
      //届け先-住所(表示用)
      this.destinationAddressDisplay = "";
      //届け先-住所(保管)
      this.shipToCharacterName = "";
      //届け先-電話番号(保管)
      this.shipToTelephoneNumber = "";
      //納品時間(保管)
      this.deliveryTime = "";
      //購入金額(保管)
      this.purchasePrice = "";
      //発注者名(保管)
      this.shipFromName = "";
      //発注者-郵便番号(保管)
      this.shipFromPostCd = "";
      //発注者-住所(保管)
      this.shipFromCharacterName = "";
      //発注者-電話番号(保管)
      this.shipFromTelephoneNumber = "";
      this.popDeliveryAdressCear();
    },
    /**
     * 出荷先ドロップダウンを初期化する
     */
    initShippingDropdown() {
      if (this.toPage == "P-TMP-903") {
        this.shippingList = [
          {
            displayText: this.$route.params.detailsList.shippingOmitNm,
            text: this.$route.params.detailsList.shippingNm,
            value: this.$route.params.detailsList.toSid,
            name: this.$route.params.detailsList.toName,
            baseCd: this.$route.params.detailsList.toBaseCd,
          },
        ];
        this.shippingSelected = this.$route.params.detailsList.toSid;
      } else {
        this.shippingList = [
          {
            displayText: this.$route.params.shippingOmitNm,
            text: this.$route.params.shippingNm,
            value: this.$route.params.shippingSelectedHeader,
            name: this.$route.params.shipTo,
            baseCd: this.$route.params.baseCd,
          },
        ];
        this.shippingSelected = this.$route.params.shippingSelectedHeader;
      }

      this.initShipping(this.shippingSelected);
    },

    // 検索監視の追加
    addSearchWatch(value) {
      this.$watch(
        () => value.search,
        (val) => {
          if (val ?? false) {
            // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
            if (val.replace(/\s+/g, "") == value.productHint.replace(/\s+/g, "")) {
              return;
            }

            // クリア処理
            value.productCd = "";
            value.productHint = "";
            value.productNm = "";
            value.productHint = "";
            value.isNew = false;
            value.productQty = null;
            value.lot = "";
            value.serial = "";
            value.dueDate = "";
            value.lotSubkey1 = "";
            value.lotSubkey2 = "";
            value.serialFlg = "0";
            value.lotFlg = "0";
            value.dueDateFlg = "0";
            value.lotSubkey1Flg = "0";
            value.lotSubkey2Flg = "0";
            value.caseSu = null;
            value.ballSu = null;
            value.baraSu = null;
            value.locationSid = null;
            value.location = null;
            value.locationHint = null;
            value.warehouseSid = null;
            value.warehouse = null;

            if (val.length >= appConfig.CNTCHARITEM) {
              this.performSearch(value, val);
            } else {
              value.productList.length = 0;
              value.productList = [];
            }
          }
        }
      );
    },

    // ロケーション検索監視の追加
    addLocaSearchWatch(value) {
      this.$watch(
        () => value.locaSearch,
        (val) => {
          if (val ?? false) {
            if (this.suppliersSelected == null || this.suppliersSelected == "") {
              // 取引先が選択されてない場合、処理しない
              return;
            }

            if (val == value.locationHint) {
              // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
              return;
            }

            // 入力桁数が定義した数異常の場合検索処理
            if (val.length >= appConfig.CNTCHARITEM) {
              this.performLocationSearch(value, val);
            } else {
              value.locationList.length = 0;
              value.locationList = [];
            }
          }
        }
      );
    },

    // 数量監視の追加
    addProductQtyWatch(value) {
      this.$watch(
        () => value.productQty,
        (val) => {
          if (val === "" || val == null) {
            this.updateTotalCount(value);
          }
        }
      );
    },

    // 商品検索処理
    performSearch(value, val) {
      const itemsBiz = getParameter.getItemsBizAddName2(
        this.searchSuppliersSelected,
        dateTimeHelper.convertUTC(this.shippingScheduleDate),
        val.replace(/\s+/g, ""),
        "1"
      );

      Promise.all([itemsBiz])
        .then((result) => {
          // 画面の初期値を設定します。
          value.productList = result[0];
          // console.debug("inputList: function (inputL) Result[0]", result[0]);
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          if (this.loadingflg) {
            this.loadingCounter = 0;
          }
        });
    },

    // ロケーション検索処理
    performLocationSearch(value, val) {
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.locationNo = val;
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.searchSuppliersSelected;
      if (value.warehouse) {
        config.params.warehouseSid = value.warehouse;
      }
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_LOCATIONSAVA, config)
          .then((response) => {
            // console.debug("$watch Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: row.locationNo,
                  value: row.locationSid,
                  name: row.locationNo,
                });
              });
              value.locationList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            if (this.loadingflg) {
              this.loadingCounter = 0;
            }
          });
      });
    },

    // 合計数の更新
    updateTotalCount(value) {
      const totalNum = Number(commonUtil.zen2han(this.totalCount).replaceAll(",", ""));
      const index = this.inputList.indexOf(value);
      const quantity = Number(commonUtil.zen2han(this.quantityList[index]).replaceAll(",", ""));
      const shipQuantity = totalNum - quantity;

      this.quantityList[index] = 0;
      this.totalCount = commonUtil.formatToCurrency(shipQuantity);

      this.inputSumList = [
        {
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        },
      ];
    },
  },

  computed: {},
  watch: {
    dateToDeliveryCal(val) {
      this.dateToDelivery = this.formatDate(val);
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    shippingScheduleDateCal(val) {
      this.shippingScheduleDate = this.formatDate(val);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    shippingStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
        this.isResBadCount++;
      } else {
        if (this.isResBadCount > 0) {
          this.isDisabledBlame = true;
          this.isResBadValue = this.isRes;
          this.isRes = "01";
        }
      }
    },
    page: function (newValue) {
      this.page = newValue;
      this.diff = this.changeList.length % this.itemsPerPage;
      this.useCache();
    },
    inputList: {
      handler(newInputList) {
        newInputList.forEach((value) => {
          if (value.isWatchAdded === void 0) {
            this.addSearchWatch(value);
            this.addLocaSearchWatch(value);
            this.addProductQtyWatch(value);
            this.$set(value, "isWatchAdded", true);
          }
        });
      },
      deep: true, // deepオプションで配列内部の変更も監視
    },
    /**
     * 出荷先取得
     */
    getShippingSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.shippingHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // From（出荷元）
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(this.shippingSchedule),
            undefined,
            this.supliersCompSid,
            val
          );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              if (this.loadingflg) {
                this.loadingCounter = 0;
              }
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}
.txt-singles ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//送り先登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}

//備考欄、テキストボックス
.search-textbox-remark {
  width: 40rem;
}
.add-textbox-remark {
  width: 115rem;
}

::v-deep #remarks {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;
      overflow-x: hidden;
      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;

  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

#listData ::v-deep th.arrivalScheduleRegistDetail {
  padding: 0px;
}

.lbl-screen-name {
  padding: 1rem 0;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: #000;
  margin-left: -15px;
}

#dialogDelivery .v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
  margin-bottom: 0px;
}

#dialogDelivery ::v-deep .error--text {
  margin-bottom: 5px;
}
</style>
